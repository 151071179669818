@import "../../colors.scss";

.container {
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 5px 0;
  .row-wrapper {
    padding: 8px 16px;
    background-color: $light-bg-color;
    border-top: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    .second {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .values {
      color: #757575;
      font-size: 12px;
      font-weight: 600;
    }
    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      .briefcase-icon,
      .email-icon {
        width: 20px;
        height: 20px;
        color: $main-orange;
        stroke-width: 2;
      }
    }
  }

  .row {
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .name-box {
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .date-box {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    .user-type {
      border: 1px solid $primary-danger-color;
      border-radius: 3px;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: 600;
      color: $primary-danger-color;
      background-color: $danger-bg-color;
      &.used {
        border: 1px solid $main-green;
        color: $main-green;
        background-color: $green-bg-with-oppacity;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    box-shadow: none;

    .row {
      gap: 5px;
      .name-box {
        width: 100%;
        justify-content: left;
        font-size: 18px;
        font-weight: 600;
        gap: 8px;
      }
      .date-box {
        width: 100%;
        justify-content: right;
        gap: 8px;
        .user-type {
          font-size: 11px;
        }
      }
    }
  }
}
