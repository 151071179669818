@import "../../colors.scss";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100dvh - var(--footer-height) - var(--header-height));
  background-color: $second-gray;
  .container {
    background-color: $light-bg-color;
    padding: 30px 60px;
    box-shadow: 0px 8px 4px 0px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 650px;
    @media screen and (max-width: 600px) {
      padding: 30px 10px;
    }
    .img-container {
      max-width: 100%;
      overflow: hidden;
      text-align: center;

      img {
        width: 100%;
      }
    }
    .email-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      p {
        font-weight: bold;
      }
    }

    .logo-container {
      text-align: center;

      img {
        max-width: 240px;
      }
    }

    .welcome-container {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-active-color;
      gap: 8px;
      margin-bottom: 20px;
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .mail-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-bottom: 20px;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      .for-what {
        font-size: 15px;
        font-weight: 500;
        color: $primary-text-color;
      }
      .email {
        font-size: 15px;
        font-weight: 700;
        svg {
          color: $main-orange;
        }
      }
    }
  }
  .input-text-box,
  .input-text-box-warning {
    border: 1px solid $primary-hover-color;
    min-height: 54px;
    &:focus {
      // outline: none;
      box-shadow: none;
      border: 2px solid $primary-hover-color;
    }
  }

  .input-text-box-warning {
    border: 1px solid $primary-danger-color;
    border-radius: 8px;
    background-color: $danger-bg-color;
    &:focus {
      border: 2px solid $primary-danger-color;
      background-color: $danger-bg-color;
    }
  }

  .hint {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .checked {
    color: #03a927;
  }

  .warning-error {
    color: #cc2929;
  }

  .input-container {
    position: relative;
  }

  .view-icon {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //   top: 6px;
    // ???
    color: gray;
    cursor: pointer;
  }

  .input-lable {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 700;
    .important {
      color: $primary-danger-color;
    }
  }
  h5.input-lable {
    margin-top: 20px;
  }
}
