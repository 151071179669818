$color-identity: #028C8C;
$color-identity-active: #025c5c;

.container {
    padding-bottom: 50px;

}

// styles.module.scss
.content {
    // max-height: 100px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.2s ease-out;
}

.expanded {
    max-height: auto;
}

.content::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
    display: block;
}

.expanded::after {
    display: none;
}

.sort-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -7px;
}

.view-more-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.view-more {
    text-decoration: none;
    color: $color-identity-active !important;
    font-size: 80% !important;
    padding: 0 25px;
    position: relative;

    &:active,
    &:focus,
    &:hover {
        text-decoration: underline;
    }
}