@import "../../colors.scss";

.main {
  padding: 0px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-container {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      flex-direction: row;
      padding-top: 40px;
      padding-bottom: 20px;
      width: 95%;
      @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
      }

      .title {
        font-size: 20px;
        font-weight: bold;
        color: $main-orange;
        display: flex;
        align-items: center;
      }
    }
  }
  .wrapper {
    width: 95%;
  }
}
