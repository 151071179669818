@import "../../colors.scss";
$color-identity: #028c8c;
$color-identity-active: #025c5c;

.container {
  // max-width: 1200px;
  // padding: 0px 10px 50px;
  margin: 0 auto;
  // position: relative;
  width: 100%;
  min-height: 100dvh;
  background-color: $light-bg-color;
  // a {
  //   // color: $color-identity;

  //   &:hover,
  //   &:focus,
  //   &:active {
  //     text-decoration: underline;
  //   }
  // }

  .error {
    max-width: 500px;
    padding: 50px 10px 50px;
    margin: 0 auto;
  }

  .consent-container {
    max-width: 600px;
    padding: 50px 10px 40px 10px;
    margin: 0 auto;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .welcome-message {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .application-detail {
      font-weight: bold;
      background: none;
      border: none;
    }
    .second-message {
      text-align: justify;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 30px;
      line-height: 25px;

      span {
        color: $primary-color;
        font-weight: 700;
      }
    }
  }
  .verification-container.small {
    height: 100dvh;
  }
  .verification-container {
    max-width: 375px;
    width: 100dvw;
    margin-top: 100px;
    min-height: 100dvh;
    padding-top: 50px;
    padding: 50px 10px 20px;
    margin: 0 auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    background-color: $light-bg-color;

    .welcome-message,
    .welcome-message p {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }

    .btn-container {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
    }

    .continue {
      width: 100%;
      font-weight: 500;

      &:focus-visible {
        box-shadow: none;
      }
    }

    .first-continue {
      width: 100%;
      font-weight: 500;

      &:focus-visible {
        box-shadow: none;
      }
    }

    .back {
      background-color: $second-gray;
      color: $default-text-color;
      border: none;
      font-weight: 400;
      width: 90%;

      &:hover {
        background-color: $secondary-border-color;
      }
    }
  }

  .results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $second-gray;
    width: 100%;
    min-height: calc(100dvh - 164px);

    .header_patient {
      height: 56px;
      background-color: #fff;
      line-height: 56px;
      width: 100%;
      border-bottom: 1px solid $main-orange;
      position: sticky;
      top: 0;
      z-index: 15;

      img {
        width: 155px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: center;

        .user-title {
          font-weight: bold;
        }

        .user-icon {
          color: $primary-color;
          font-weight: bold;
          margin-right: 10px;
        }
      }
    }
  }
}

.large-input-label {
  display: block;
  font-weight: bold;
  // text-transform: uppercase;
  padding-bottom: 5px;
  text-align: left;
  margin-bottom: 10px;
}

.veification-input-container {
  width: 100%;
  height: 74px;
}

.input-character {
  height: 74px;
  width: 74px;
  line-height: 74px;
}

.agree-terms-service {
  font-size: 90%;
}

.small-logo-container {
  max-width: 250px;
  text-align: center;
  display: block;
  margin: 0 auto 20px;

  &.header-left {
    text-align: left;
    display: inline-block;
    margin: 0;
  }

  img {
    width: 100%;
  }
}

.inital-info-summary {
  // padding: 5px 15px;
  font-size: 18px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  .column-value {
    font-weight: 500;
    // font-size: 20px;
    color: $primary-hover-color;
    text-align: right !important;
  }

  .column-label {
    display: flex;
    gap: 12px;

    flex-wrap: nowrap;
    font-size: 14px;
    font-weight: bold;
    color: $default-text-color;

    svg {
      height: 24px;
      width: 24px;
      color: $fifth-gray;
    }
  }
}

.row-label {
  color: $primary-hover-color;
  font-size: 20px;
  font-weight: 400;
  // text-align: center;

  margin-bottom: 20px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .container {
    // max-width: 375px;
    // padding: 50px 10px;

    .results-container {
      padding: 0;
      min-height: calc(100dvh - 233px);
    }

    .error {
      max-width: 375px;
      padding: 50px 10px;
    }

    .consent-container,
    .verification-container {
      // max-width: 375px;
      padding: 50px 20px;
    }
  }

  // .small-logo-container {
  //   &.header-left {
  //     max-width: 100px;
  //   }
  // }
}

.list-item {
  display: flex;
  gap: 8px;

  &.last-item {
    border-bottom: 1px solid $light-blue;
  }

  .tick-icon {
    width: 24px;
    height: 24px;
  }

  .terms {
    font-size: 16px;
    margin-bottom: 15px;

    font-weight: 500;
    line-height: 25px;

    // @media screen and (max-width: 600px) {
    //   line-height: 25px;
    //   font-size: 13px;

    //   a {
    //     font-size: 13px;
    //   }
    // }

    a {
      font-size: 16px;
      color: $primary-color;
      word-wrap: break-word;
      display: inline;
      font-weight: 600;

      svg {
        height: 12px;
        width: 12px;
        color: rgba(0, 119, 117, 0.3);
      }

      &:hover {
        color: $color-identity-active;
        text-decoration: underline;
      }
    }

    @media screen and (min-width: 600px) {
      text-align: justify;
      line-height: 30px;
    }
  }
}

.header-nav {
  padding: 0 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 18px;
}

ol.policy-list,
ul.policy-list {
  list-style: none !important;
  margin-top: 10px;
  padding: 10px 0;
  // border: solid 1px #ccc;
  font-size: 90%;
  border-left: 0;
  border-right: 0;
  text-indent: 0;

  li {
    // margin: 10px 20px;
    padding-bottom: 5px;
    text-indent: 0;
  }
}

.agree-container {
  display: flex;
  // gap: 8px;
  align-items: flex-start;

  .agree-label {
    font-size: 16px;
    text-align: justify;
    line-height: 30px;
    font-weight: 500;

    @media screen and (max-width: 600px) {
      line-height: 25px;
    }

    a {
      font-size: 16px;
      color: $primary-color;
      word-wrap: break-word;
      display: inline;
      font-weight: 600;

      svg {
        height: 12px;
        width: 12px;
        color: rgba(0, 119, 117, 0.3);
      }

      &:hover {
        color: $color-identity-active;
        text-decoration: underline;
      }
    }
  }
}

.agree-label {
  margin-right: 0;
}

.results-container {
  .lender-logo-container {
    display: inline-table;

    .parent {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

    height: 128px;
    width: 128px;

    img {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  .congratulations {
    color: $color-identity;
    // text-transform: uppercase;
    margin: 15px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  .loanOptions {
    color: $main-orange;
    font-size: 1.25rem;
    font-weight: bold;
  }

  .lenders-message {
    font-size: 18px;
    font-weight: 700;
    margin-left: 2px;
  }

  .pre-approved-message {
    width: 100%;

    @media screen and (min-width: 800px) {
      text-align: center;
    }
  }

  .main_patient_box {
    padding: 0 15px;
    width: 100%;
    max-width: 600px;

    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
  }

  .applicant-container {
    .applicant-header {
      background-color: #fff;
      display: block;
      padding: 10px 16px;
      width: 100%;
      line-height: 20px;
      margin-block: 20px;
      box-shadow: 0px 2px 4px 0px #00000026;

      // .item-header {
      //   // height: 56px;
      //   line-height: 56px;
      // }
      .line-break {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid $light-blue;
      }

      .header-title {
        padding: 10px;
        text-align: left;

        display: flex;
        gap: 8px;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }

        .icon1 {
          color: $main-orange;
        }

        .icon2 {
          color: $primary-color;
        }

        .icon3 {
          color: $main-purple;
        }

        @media screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .header-value {
        padding: 10px;

        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          // border: 1px solid $main-orange-with-oppacity;
          padding: 4px 8px;
          border-radius: 4px;
          text-align: right;
          width: max-content;
        }

        @media screen and (max-width: 600px) {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
        }
      }

      div {
        row-gap: 8px;
      }
    }
  }
}

.loan-option-container {
  padding: 0 15px;
  width: 100%;
  max-width: 600px;

  .card {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    margin-bottom: 16px;

    .company-title {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      position: relative;

      img {
        height: 150px;

        @media screen and (max-width: 600px) {
          height: 70px;
        }
      }

      .Sponsored,
      .offered {
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        font-size: 13px;
        font-weight: 400;
        padding: 4px;
        border-radius: 0 0 0 4px;
      }

      .Sponsored {
        background-color: $main-purple;
      }

      .offered {
        background-color: $main-orange;
      }

      h5 {
        text-align: center;
        color: black;
        font-weight: 400;
      }
    }

    .info1 {
      background-color: #f5f5f580;
      display: flex;
      flex-direction: column;
      align-items: center;

      .line {
        height: 1px;
        background-color: #d9d9d9;
        width: 95%;

        @media screen and (max-width: "450px") {
          width: 90%;
        }
      }
    }
  }

  .logo-link {
    text-transform: uppercase;
    color: $color-identity;
  }

  // .card {
  //   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  //   margin-bottom: 16px;
  // }

  .apply-button {
    background-color: $color-identity;
    box-shadow: none;
    margin: 0 16px 16px 16px;

    &.small {
      padding: 3px 5px 5px;
      border-radius: 5px;
      font-size: 14px;
      height: auto;
    }

    &:hover,
    &:active {
      background-color: $color-identity-active;
      box-shadow: 0px 4px 16px rgba(var(--primary-rgb), 0.3);
    }

    &.outline {
      background-color: transparent;
      border: solid 1px $color-identity;

      color: $color-identity;

      &:hover,
      &:active {
        background-color: $color-identity-active;
        border: solid 1px $color-identity-active;
        color: #fff;
      }
    }
  }

  .view-more {
    text-decoration: none;
    color: $color-identity-active;
    font-size: 90%;

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .view-more-details-container {
    position: relative;
    // height: 50px;
    // overflow: hidden;
  }

  .view-more-table {
    font-size: 14px;

    thead {
      font-weight: 500;
    }

    tbody {
      td {
        padding-right: 8px;
      }
    }
  }

  .btnFilter {
    margin: 4px 0 0 0;
    // flex-grow: 1;
    position: relative;
    border: solid 1px $primary-border-color;
    color: $default-text-color;
    padding: 4px auto;
    background: transparent;
    font-size: 0.7rem !important;
    width: 100%;
    height: auto;
    padding: 5px 5px;
    border-radius: 5px;
    font-weight: 500;
    // text-align: left;

    &:hover,
    &:active,
    &.active {
      font-weight: bold;
      border: none;
      background-color: $primary-hover-color;
    }

    &.active {
      background-color: #028c8c4a;
      color: $default-text-color;

      &:focus,
      &:hover {
        background-color: #028c8c4a;
      }
    }

    .sort-icon {
      margin-left: 5px;
      // position: absolute;
      // right: 3px;
      // top: 50%;
      // margin-top: -7px;
    }
  }

  .info {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .infoLabel {
    display: flex;
    gap: 8px;

    svg {
      color: $main-orange;
    }

    @media screen and (max-width: 600px) {
      font-size: 13px !important;
    }
  }

  .infoValue {
    text-align: left;
    padding-left: 10px;

    &.bigNum {
      font-size: 24px !important;
    }

    @media screen and (max-width: 600px) {
      font-size: 13px !important;
    }
  }

  .applyButton {
    font-size: 0.9rem;
    padding: 8px 16px;
    width: auto;
  }

  .sticky-header-container {
    border-bottom: solid 2px $color-identity;
    padding-left: 16px !important;
  }

  .filters {
    // display: flex;
    // justify-content: center;
    // position: -webkit-sticky;
    // /* For Safari */
    // position: sticky;
    display: block;
    top: 0;
    width: 100%;
    // overflow-x: scroll;
    white-space: nowrap;
    padding: 0 0;

    // margin-bottom: 20px;
    .inner {
      display: block;
      // min-width: 650px;
      text-align: center;
      padding-bottom: 5px;

      .btnFilter button:last-child {
        margin-right: 0;
      }
    }
  }

  $filter-max-width-activation: 650px;

  @media (max-width: $filter-max-width-activation) {
    .filters {
      // display: flex;
      // justify-content: center;
      // position: -webkit-sticky;
      // /* For Safari */
      // position: sticky;
      display: block;
      top: 0;
      width: 95%;
      // overflow-x: scroll;
      white-space: nowrap;

      // margin-bottom: 20px;
      .inner {
        display: block;
        // min-width: $filter-max-width-activation;
        text-align: left;
        padding-bottom: 5px;
      }
    }
  }

  .cardBody {
    padding: 20px;
  }

  .site-links {
    display: flex;
    flex-direction: row;
    gap: 16px;
    font-size: 13px;
  }

  @media (max-width: 768px) {
    .btnFilter {
      font-size: 97%;

      // .sort-icon {
      //   // position: absolute;
      //   // right: 5px;
      //   // top: 50%;
      //   // margin-top: -5px;
      // }
    }

    .infoLabel,
    .infoValue {
      text-align: left;
      font-size: 1rem;
    }
  }
}

.filter-container {
  margin-top: 20px;
  width: 100%;
  padding: 0;
  max-width: calc(100vw - 20px);
  position: sticky;
  top: 56px;
  z-index: 5;

  &.sticky {
    padding-bottom: 5px;

    // border: solid 1px $color-identity;
    // border-top: 0;
    // margin-top: -5px;
  }
}

.btn {
  border: 1px solid #d9d9d9 !important;
  background-color: transparent !important;
  color: #1e1e1e !important;
  font-size: 13px !important;
  font-weight: 400 !important;

  &:hover {
    background-color: $second-gray !important;
  }

  &.active {
    background-color: $primary-bg-color-with-oppacity !important;
    font-weight: 700 !important;
  }
}

.flex-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .payment {
  }
}

.help-mail {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: $primary-color;
  padding: 8px;
  border: 1px solid $primary-color;
  max-width: fit-content;

  margin-top: 20px;

  svg {
    color: $main-orange;
  }
}

.invalid-back {
  background-color: $primary-bg-color-with-oppacity;
  color: $default-text-color;
  border: 1px solid $primary-color;
  color: $primary-color;
  font-weight: 400;
  width: 150px;
  margin-bottom: 50px;

  &:hover {
    background-color: $color-identity;
    border: 1px solid $primary-color;
  }

  &:active {
    background-color: $color-identity !important;
  }
}

.processing-container {
  margin: auto;
  position: relative;
  min-height: 300px;
  max-width: 800px;
  text-align: center;
  padding: 50px 10px;
}
.description-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  .description-icon {
    color: $main-orange;
    font-size: 20px;
    margin-right: 5px;
  }
  .description {
  }
  .description2 {
    font-style: italic;
  }
}
.info-title {
  width: 100%;
  text-align: center;
  line-height: 0.1em;
  margin: 3.5rem 0 3.5rem;
  border-bottom: 4px solid $primary-bg-color;
  font-weight: bold;
  color: $primary-bg-color;

  span {
    background-color: $light-bg-color;
    padding: 0 10px;
  }
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  &.last {
    margin-bottom: 1.5rem;
  }
}
.input {
  input {
    height: 42px;
  }
  &.not-valid {
    div:last-child {
      border: 1px solid $primary-danger-color;
      background-color: #fcf2f2;
    }
  }
  &.invalid {
    background-color: #fcf2f2;
  }
}
.prefill-error {
  // font-size: 0.75rem;
  color: red;
  margin-block: 1rem;
  padding-inline: 0.5rem;
}
