@import "../../colors.scss";

/* The container */
.container {
  font-size: 10px;
  align-items: center;
  color: $fifth-gray;
  text-align: justify;
  display: inline-flex;
  gap: 8px;
  // max-width: 190px;
  padding: 10px 0;
  margin: 0 4px;
  transition: 0.5s;
  span {
    text-wrap: pretty;
    text-align: left;
    // display: inline;
    // max-width: 85px;
  }

  .indicator {
    // display: inline;
    transition: 0.5s;
    background-color: $fourth-gray;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &.in-progress {
      transition: 0.5s;
      background-color: $light-bg-color;
      border: 1px solid $green-confirm;
      &::after {
        transition: 0.5s;
        content: "";
        background-color: $green-confirm;

        position: absolute;
        width: 12px;
        height: 12px;
        // top: 6px;
        // left: 6px;
        border-radius: 50%;
      }
    }
    &.completed {
      transition: 0.5s;
      background-color: $green-confirm-with-oppacity;

      &::after {
        transition: 0.5s;
        content: "";

        border-right: 3px solid $green-confirm;
        border-bottom: 3px solid $green-confirm;
        position: absolute;
        width: 8px;
        height: 14px;
        top: 4px;
        left: 8px;
        transform: rotateZ(45deg);
        border-radius: 4px;
      }
    }
  }
  &.in-progress {
    transition: 0.5s;
    color: $dark-gray;
  }
  &.completed {
    transition: 0.5s;
    color: $green-confirm;
  }
}
.line {
  // display: inline;
  transition: 0.5s;
  width: 50px;
  height: 1px;
  background-color: $fourth-gray;
  &.in-progress {
    transition: 0.5s;
    background-color: $green-confirm;
  }
  &.completed {
    transition: 0.5s;
    background-color: $green-confirm;
  }
  @media screen and (max-width: "600px") {
    width: 25px;
  }
}
