@import "../../colors.scss";

/* The container */
.container {
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  display: block;
  position: relative;
  padding-left: 30px;
  //   margin-bottom: 12px;
  cursor: pointer;
  //   font-size: 22px;
  //   -webkit-user-select: none;
  //   -moz-user-select: none;
  //   -ms-user-select: none;
  //   user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #eee;
  border: 1px solid $primary-border-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: 2px solid $primary-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  // position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 3px;
  top: 3px;
  width: 12px;
  height: 12px;
  background-color: $primary-color;
  border-radius: 50%;
}
