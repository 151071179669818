.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // max-width: 1204px;

    &.sticky {
        position: fixed;
        z-index: 1000;
        background-color: #fff;
        padding: 5px 0;
        width: initial;
        left: 0;
        right: 0;
        // background-color: red;
    }


}

@media (max-width: 1024px) {
    .container {
        &.sticky {
            left: 0;
            right: 0;
            width: initial;
        }
    }
}