@import "../../colors.scss";

.container {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .first-row,
  .second-row,
  .main-row {
    width: 90%;
  }

  .first-row {
    background-color: $primary-bg-color-with-oppacity;
    border-radius: 8px;
    padding: 8px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 2px;
    }

    .user-role {
      color: $primary-color;
      font-weight: 600;
    }
  }

  .second-row {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      color: $main-orange;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .main-row {
    background-color: $light-bg-color;
    border-radius: 8px;
    margin-top: 20px;
    height: 100%;
    padding: 20px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 20px 0;
      gap: 10px;
      cursor: pointer;
      .icon {
        height: 150px;
        width: 150px;
        border: 5px solid $primary-bg-color-with-oppacity;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-color;
        position: relative;
        transition: 0.2s;
        img {
          width: 60%;
          height: 60%;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: $fifth-gray;
        transition: 0.2s;
      }
      &:hover {
        .icon {
          border: 1px solid $main-orange;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            border: 8px solid $main-orange-with-oppacity;
            border-radius: 50%;
          }
        }
        .title {
          color: black;
        }
      }
    }
  }
}
