@import "../../colors.scss";

.react-select__control {
  border-radius: 8px !important;
  min-height: 30px !important;
  width: 100%;
}

.react-select__value-container {
  padding: 0 4px;
  border: none !important;
  background-color: transparent !important;
}

.react-select__input-container {
  border: none !important;
  background-color: transparent !important;
}

.react-select__indicators {
  border: none !important;
  background-color: transparent !important;
}

.react-select__indicator {
  border: none !important;
  background-color: transparent !important;
}

.react-select__menu {
  div {
    border: none !important;
    background-color: transparent !important;
  }

  border: 1px solid $light-bg-color !important;

  .react-select__control--is-focused {
    border-color: $primary-color !important;
    // box-shadow: 0 0 0 1px $primary-color !important;
    box-shadow: none !important;
  }

  .react-select__option--is-focused {
    background-color: $second-gray !important;
    // color: $light-bg-color !important;
    // Optional: Adjust text color for contrast
  }

  .react-select__option--is-selected {
    background-color: $primary-bg-color-with-oppacity !important;
    color: $primary-color;
    font-weight: 500;
  }
}
