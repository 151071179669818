@import "../../../../colors.scss";

.text {
  font-size: 14px;
  margin: 0 0 10px;
}
.list {
  //   margin-bottom: 0;
  font-size: 14px;
  .initiated {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $primary-color;
    background-color: $main-green-with-oppacity;
  }
  .pending-applicant-submission {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $main-orange;
    background-color: $main-orange-with-oppacity;
  }
  .submitted {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $dark-green;
    background-color: $green-confirm-with-oppacity;
  }
  .processing {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $processing-color;
    background-color: $processing-color-with-opacity;
  }

  .processed-successfuly-with-approval {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $iris-color;
    background-color: $iris-color-with-oppacity;
  }

  .processed-no-approval {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $no-approval-color;
    background-color: $no-approval-color-with-opacity;
  }

  .processing-error {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $dark-red;
    background-color: $dark-red;
  }

  .applicant-applied {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $applied-color;
    background-color: $applied-color-with-opacity;
  }

  .abandoned {
    font-weight: 500;
    margin: 4px 0;
    max-width: 250px;
    color: $third-gray;
    background-color: $secondary-border-color;
  }
}
.ordered-list {
  //   margin-bottom: 0;
  font-size: 14px;
}
.inner-title {
  color: $primary-color;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
}
.link {
  color: $primary-hover-color;
  &:hover {
    text-decoration: underline;
  }
}

.image {
  border: 1px solid #e0e0e0;
  max-width: 99%;
  border-radius: 5px;
  margin: 5px 0 10px 0;
}
