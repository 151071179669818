@import "../../colors.scss";

.input {
  height: 56px;
  border: 1px solid #007775;

  &:focus {
    box-shadow: 0px 0px 4px 2px #00777529;
  }
}

.select-control {
  width: 100%;
}

.inactive-item {
  font-style: italic;
  color: #aaa;
}