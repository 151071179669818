@import "../../colors.scss";

.container {
  width: 100%;
  // height: 120px;
  background-color: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 5px 0;
  .top-section {
    width: 100%;

    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 10px 16px 10px;
    margin: 0;
    .date {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #007775;
      font-size: 18px;
    }
    .top-right-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .payment-status {
        // width: 150px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 8px;
        p {
          font-size: 11px;
          line-height: 12px;
          width: 42px;
          margin-right: 10px;
          color: #757575;
          text-align: left;
        }
        .status {
          // width: 46px;
          height: 100%;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 600;
          padding: 4px 8px;
        }
        .Paid {
          background-color: #ebffee;
          color: #14ae5c;
        }
        .Void {
          color: #7d7d7d;
          background-color: #d9d9d9;
        }
        .Issued {
          color: #c00f0c;
          background-color: #fee9e7;
        }
      }
      .link-icon {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00777526;
        color: #007775;
      }
    }
  }
  .bottom-section {
    width: 100%;
    margin: 0;
    // max-height: 70px;
    padding: 10px 0px 10px 0px;

    @media screen and (max-width: 768px) {
      // background-color: $main-orange-with-oppacity;
      svg {
        color: $main-orange;
      }
    }

    p {
      text-align: left;
      // max-width: 80px;
      font-size: 12px;
      margin-left: 0px;
      margin-bottom: 0;
      display: inline;
      color: $fifth-gray;
    }
    .icon {
      display: inline;
      width: 26px;
      height: 26px;
    }
    .column {
      display: flex;
      align-items: center;
      gap: 5px;
      @media screen and (max-width: 992px) {
        justify-content: center;
      }
      @media screen and (max-width: 768px) {
        justify-content: flex-start;
      }
    }
    .columnTwo {
      @media screen and (max-width: 992px) {
        display: flex;
        justify-content: center;
      }
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }
    .data-column {
      font-weight: bold;
      font-size: 16px;
      text-align: right;
    }
    .first-column {
      display: flex;
      align-items: center;
      padding: 10px;
      height: 60px;
    }
    .middle-column {
      display: flex;
      align-items: center;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      @media screen and (max-width: 768px) {
        border: none;
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
      }
      padding: 10px;
      height: 60px;
    }
    .last-column {
      display: flex;
      align-items: center;
      padding: 10px;
      height: 60px;

      p {
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .middle-column {
    border: 1px solid #d9d9d9;
    // width: 100%;
    margin-top: 0;
  }
  .data-column {
    font-size: 18px;
  }
  .last-column {
    margin-top: 0;
  }
}
