// colors.scss
:root {
  --primary-color: #007775;
  --primary-bg-color: #007775;
  --primary-hover-color: #149694;
  --primary-active-color: #005555;
  --primary-danger-color: #cc2929;
  --primary-danger-bg: #fcf2f2;
  --primary-bg-color-with-oppacity: #00777526;
  --primary-border-color: #b2b2b2;
  --primary-text-color: #767676;
  --secondary-bg-color: #00757540;
  --secondary-border-color: #d9d9d9;
  --secondary-danger-color: #c00f0c;
  --second-gray: #f5f5f5;
  --third-gray: #6f6f6f;
  --main-orange: #f59c00;
  --main-orange-with-oppacity: #f59c001a;
  --main-purple: #5d5fef;
  --main-green: #009951;
  --green-confirm: #309f10;
  --green-confirm-with-oppacity: #309f1033;
  --main-green-with-oppacity: #0099511a;
  --main-gray: #424241;
  --dark-gray: #555555;
  --fourth-gray: #b3b3b3;
  --fifth-gray: #757575;
  --main-blue: #7173f1;
  --gray-color-with-oppacity: #00000016;
  --green-bg-with-oppacity: #0099511a;
  --purple-with-oppacity: #5d5fef1a;
  --button-color: #006eff;
  --default-text-color: black;
  --danger-bg-color: #cc29291a;
  --light-bg-color: white;
  --light-bg-color-with-oppacity: #ffffff60;
  --light-blue: #cce4e3;
  --filter-placeholder-color: #808080;
  --filter-border-color: #cccccc;
  --dark-green: #28850d;
  --dark-green-with-oppacity: #28850d1a;
  --green-confirm: #309f10;
  --green-confirm-with-oppacity: #309f1033;
  --iris-color: #7879ef;
  --iris-color-with-oppacity: #7879ef1a;
  --dark-red: #bb0000;
  --mid-gray: #a5a5a5;
  --processing-color: #0d2985;
  --processing-color-with-opacity: #0d29851a;
  --no-approval-color: #eb0404;
  --no-approval-color-with-opacity: #eb04041a;
  --applied-color: #0875c4;
  --applied-color-with-opacity: #0875c41a;
}

/* Optional: Assign CSS variables to SCSS variables for use within SCSS */
$primary-color: var(--primary-color);
$primary-bg-color: var(--primary-bg-color);
$primary-hover-color: var(--primary-hover-color);
$primary-active-color: var(--primary-active-color);
$primary-danger-color: var(--primary-danger-color);
$primary-danger-bg: var(--primary-danger-bg);
$primary-border-color: var(--primary-border-color);
$primary-text-color: var(--primary-text-color);
$primary-bg-color-with-oppacity: var(--primary-bg-color-with-oppacity);
$main-orange: var(--main-orange);
$main-gray: var(--main-gray);
$dark-gray: var(--dark-gray);
$main-green: var(--main-green);
$green-confirm: var(--green-confirm);
$green-confirm-with-oppacity: var(--green-confirm-with-oppacity);
$main-orange-with-oppacity: var(--main-orange-with-oppacity);
$main-green-with-oppacity: var(--main-green-with-oppacity);
$main-purple: var(--main-purple);
$main-blue: var(--main-blue);
$secondary-bg-color: var(--secondary-bg-color);
$secondary-danger-color: var(--secondary-danger-color);
$secondary-border-color: var(--secondary-border-color);
$second-gray: var(--second-gray);
$third-gray: var(--third-gray);
$fourth-gray: var(--fourth-gray);
$fifth-gray: var(--fifth-gray);
$gray-color-with-oppacity: var(--gray-color-with-oppacity);
$danger-bg-color: var(--danger-bg-color);
$green-bg-with-oppacity: var(--green-bg-with-oppacity);
$light-bg-color: var(--light-bg-color);
$light-bg-color: var(--light-bg-color);
$light-bg-color-with-oppacity: var(--light-bg-color-with-oppacity);
$default-text-color: var(--default-text-color);
$purple-with-oppacity: var(--purple-with-oppacity);
$button-color: var(--button-color);
$light-blue: var(--light-blue);
$filter-placeholder-color: var(--filter-placeholder-color);
$filter-border-color: var(--filter-border-color);
$dark-green: var(--dark-green);
$dark-green-with-oppacity: var(--dark-green-with-oppacity);
$green-confirm: var(--green-confirm);
$green-confirm-with-oppacity: var(--green-confirm-with-oppacity);
$iris-color: var(--iris-color);
$iris-color-with-oppacity: var(--iris-color-with-oppacity);
$dark-red: var(--dark-red);
$mid-gray: var(--mid-gray);
$processing-color: var(--processing-color);
$processing-color-with-opacity: var(--processing-color-with-opacity);
$no-approval-color: var(--no-approval-color);
$no-approval-color-with-opacity: var(--no-approval-color-with-opacity);
$applied-color: var(--applied-color);
$applied-color-with-opacity: var(--applied-color-with-opacity);
