// $primary-color: #333;
// $primary-bg-color: #ffff;
@import "./colors.scss";

:root {
  --global-padding-sides: 80px;

  //customize toastify
  --toastify-color-info: #5d5fef;
  --toastify-color-success: #03a927;
  --toastify-color-warning: #f59c00 !important;
  --toastify-color-error: #c00f0c !important;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --header-height: 63px;
  --footer-height: 164px;
  @media screen and (max-width: 600px) {
    --header-height: 53px;
    --footer-height: 233px;
  }
}

@media screen and (max-width: 900px) {
  :root {
    --global-padding-sides: 60px;
  }
}

@media screen and (max-width: 780px) {
  :root {
    --global-padding-sides: 40px;
  }
}

@media screen and (max-width: 650px) {
  :root {
    --global-padding-sides: 20px;
  }
}

@media print {
  :root {
    --global-paddind-sides: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;

  // font-weight: <weight>;
  // font-style: normal;
}

html,
body,
#root {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  // overflow-x: hidden;
  // background-color: #f5f5f5;
}

#root {
  // background-color: var(--second-gray);
  background-color: var(--light-bg-color);

  // display: flex;
  // flex-direction: column;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4em;

  padding-bottom: 50px;
}

.relative {
  position: relative;
}

.btn {
  font-weight: 300;

  &.btn-primary {
    background-color: $primary-color;
    border: $primary-color;

    &.btn-dark {
      background-color: $primary-active-color;
    }

    &:hover {
      background-color: $primary-hover-color;
    }

    &:active {
      background-color: $primary-active-color;
    }
  }
}

// input {
//   border-color: $primary-bg-color;
//   height: 40px;
// }
// price range slider styles starts here ....
.horizontal-slider {
  // position: relative;
  width: 100%;
  // max-width: 500px;
  // height: 50px;
  // border-radius: 100px;
  background-color: $light-bg-color-with-oppacity;
  // border: 1px solid $primary-border-color;
  // top: -20px;
}

.horizontal-slider .example-track {
  // top: 22.5px;
  height: 10px;
  position: absolute;
  border-radius: 5px;
  // left: 0px;
  // right: 106.922px;
  // will-change: left, right;
}

.example-track-0 {
  background-color: $primary-bg-color-with-oppacity;
}

.example-track-1 {
  background-color: $primary-color;
}

.example-track-2 {
  background-color: $primary-bg-color-with-oppacity;
}

.horizontal-slider .example-thumb {
  width: 24px;
  height: 24px;
  border: 4px solid $light-bg-color;
  position: relative;
  top: -7px;
  line-height: 65px;
  font-size: smaller;
  // font-weight: 600;
  // color: transparent;
  color: $main-orange;
  // padding-left: 20px;
  // top: 15px;
  // position: absolute;
  // font-size: 1.1em;
  // font-weight: bold;
  // text-align: center;

  cursor: pointer;
  // box-sizing: border-box;
  // outline: none;
  &::before {
    content: "$";
    // position: absolute;
  }
}

.example-thumb-0 {
  background-color: $primary-color;
  display: flex;
  justify-content: center; /* Centers the text horizontally */
  &:hover {
    box-shadow: 0 0 0 4px $primary-bg-color-with-oppacity;
    font-weight: 600;
  }
}

.example-thumb-1 {
  background-color: $primary-color;
  display: flex;
  justify-content: center; /* Centers the text horizontally */
  &:hover {
    box-shadow: 0 0 0 4px $primary-bg-color-with-oppacity;
    font-weight: 600;
  }
}

//price rage slider styles ends

.form-control {
  height: 56px;

  &::-webkit-inner-spin-button {
    display: none;
  }

  &.danger {
    border-color: $primary-danger-color;
    background-color: #fcf2f2;

    &:focus {
      box-shadow: none;
      border: 2px solid $primary-danger-color;
    }
  }

  &:focus {
    box-shadow: none;
    border: 2px solid $primary-bg-color;
  }
}

.vi__character {
  line-height: 60px;
  border-radius: 5px;
  height: 60px;
  border-color: $primary-hover-color;

  @media screen and (max-width: 600px) {
    line-height: 50px;
    height: 50px;
  }
}

.vi__character--inactive {
  background-color: transparent;
  border-color: $primary-hover-color;
}

.large-input-label {
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 5px;
  text-align: left;
}

.link_primary {
  color: $primary-active-color;
  font-weight: bold;
}

.page-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

// .react-select__control {
//   height: 56px;
//   border: 1px solid $primary-color;
// }
