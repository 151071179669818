@import "../../../colors.scss";

.container {
  position: relative;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  // margin-block: 20px;
  .table {
    width: 100%;
    min-width: 480px;
    border-radius: 8px;
    background-color: $light-bg-color;
    @media screen and (max-width: 900px) {
      min-width: 300px;
    }
    tbody {
      tr:hover {
        background-color: $second-gray;
      }
    }
    th,
    td {
      width: 25%;
    }
  }
  .table-header {
    border-bottom: 1px solid $secondary-border-color;
    font-size: 12px;

    .title {
      text-align: center;
      color: $primary-text-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 16px 0;
      img {
        width: 32px;
        height: 32px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 12px;
        font-weight: 600;
        img {
          width: 24px;
          height: 24px;
        }
      }
      @media screen and (max-width: 800px) {
        font-size: 10px;
      }
    }
  }
  .table-body {
    font-size: 14px;

    font-weight: 600;
    border-bottom: 1px solid $second-gray;
    .title {
      text-align: center;
      color: $primary-text-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 16px 0;
      img {
        width: 32px;
        height: 32px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 12px;
        font-weight: 600;
        img {
          width: 24px;
          height: 24px;
        }
      }
      @media screen and (max-width: 800px) {
        font-size: 10px;
      }
    }
    .amount {
      font-size: 20px;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 16px;
      }
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
      text-align: center;
      vertical-align: middle;
      font-weight: 500;
      height: 100%;
      &.submitted {
        color: $main-green;
      }
      &.pre-aprroved {
        color: $main-blue;
      }
      &.prospects {
        color: $main-orange;
      }
    }
  }
}
