@import "../../colors.scss";

.container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .first-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 40px;
    .search-container {
      position: relative;
      width: 40%;
      display: flex;
      align-items: center;
      .search-text {
        width: 100%;
        height: 40px;
        outline-color: $light-bg-color;
        border: 1px solid $light-bg-color;
        border-radius: 8px;
        padding: 10px;
        transition: 0.5s;
        &:hover {
          outline: 1px solid $primary-border-color;
        }
        &:focus-visible {
          outline: 1px solid $primary-bg-color;
        }
      }
      .search-icon {
        position: absolute;
        right: 8px;
        cursor: pointer;
        color: $primary-text-color;
      }
    }
  }

  .filter-container {
    width: 100%;
    display: flex;
    padding: 12px;
    align-items: center;

    border: none;
    background-color: $light-bg-color;
    gap: 10px;
    .filter {
      min-height: 30px;
      width: 200px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      font-size: smaller;
      &:focus-visible {
        outline: 1px solid $primary-bg-color;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .container {
    .first-row {
      .search-container {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .container {
    .first-row {
      .search-container {
        width: 60%;
      }
    }
  }
}
