@import "../../colors.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: $light-bg-color;
  background-color: $primary-bg-color;
  border: none;
  border-radius: 8px;
  width: 35%;
  transition: 0.5s;
  &:hover {
    background-color: $primary-hover-color;
  }
  &:active {
    background-color: $primary-active-color;
  }
}

.no-user {
  color: $secondary-danger-color;
  text-align: center;
  background-color: $light-bg-color;
  padding: 12px;
}

@media screen and (max-width: 800px) {
  .btn {
    font-size: 14px;
  }
}
