@import "../../colors.scss";

.container {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-bottom: none;
  // margin: 8px 0;
  width: 100%;
  margin: 5px 0;
  .first-row,
  .second-row,
  .third-row,
  .last-row {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .first-row,
  .second-row {
    padding: 8px;
  }
  .first-row {
    .name-date-box {
      display: flex;
      align-items: center;
      gap: 16px;
      .name-box {
        font-size: 18px;
        font-weight: 500;
      }
      .date-box {
        font-size: 12px;
        font-weight: 600;
        color: #757575;
        display: flex;
        align-items: center;
        justify-content: end;
        .date-icon {
          width: 18px;
          height: 18px;
          stroke-width: 2;
        }
        span {
          margin-left: 5px;
          height: 10px;
          line-height: 10px;
          text-align: center;
        }
      }
    }
    .action-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      .buttons-box {
        align-items: center;
        display: flex;
        gap: 16px;
        .icon-box-disabled {
          // border-radius: 100%;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: $second-gray;
          //? background-color: rgb($primary-color, 0.15);
          border: none;
          color: $fourth-gray;
          display: flex;
          align-items: center;
          justify-content: center;
          // cursor: pointer;
          .icon {
            width: 20px;
            height: 20px;
            stroke-width: 2;
          }
        }
        .icon-box {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: rgb(0, 119, 117, 0.15);
          //? background-color: rgb($primary-color, 0.15);
          border: none;
          color: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .icon {
            width: 20px;
            height: 20px;
            stroke-width: 2;
          }
        }
      }
      .application-status {
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
        font-weight: 600;
        @media screen and (max-width: 800px) {
          font-size: 12px;
          font-weight: 500;
        }
        &.initiated {
          color: $primary-color;
          background-color: $main-green-with-oppacity;
        }
        &.pending-applicant-submission {
          color: $main-orange;
          background-color: $main-orange-with-oppacity;
        }
        &.submitted {
          color: $dark-green;
          background-color: $green-confirm-with-oppacity;
        }
        &.processing {
          color: $processing-color;
          background-color: $processing-color-with-opacity;
        }

        &.processed-successfuly-with-approval {
          color: $iris-color;
          background-color: $iris-color-with-oppacity;
        }

        &.processed-no-approval {
          color: $no-approval-color;
          background-color: $no-approval-color-with-opacity;
        }

        &.processing-error {
          color: white;
          background-color: $dark-red;
        }

        &.applicant-applied {
          color: $applied-color;
          background-color: $applied-color-with-opacity;
        }

        &.abandoned {
          color: $third-gray;
          background-color: $secondary-border-color;
        }

        &.processed-no-lender {
          color: $main-blue;
          background-color: $light-blue;
        }

        &.lender-link-clicked {
          color: $main-blue;
          background-color: $dark-green-with-oppacity;
        }
      }
    }
  }
  .second-row {
    background-color: $second-gray;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    cursor: pointer;
    &:hover {
      background-color: #e5e5e5;
    }
    .icon {
      color: #a6a6a6;
      height: 22px;
      width: 22px;
    }
    .first-col,
    .second-col,
    .third-col {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 0;
    }
    .first-col {
      justify-content: flex-start;
    }
    .second-col {
      justify-content: center;
    }
    .third-col {
      justify-content: flex-end;
    }
  }
  .third-row {
    margin: 0;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #d9d9d9;
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .first-col,
    .middle-col,
    .last-col {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .seprator {
      height: 50px;
      width: 1px;
      background-color: #d9d9d9;
    }
    .first-col {
      padding-left: 0;
      margin-right: 8px;
    }
    .middle-col {
      justify-content: center;
      margin: 0 8px 0 8px;
      .value {
        font-size: 14px;
        @media screen and (max-width: 800px) {
          font-size: 12px;
        }
      }
    }
    .last-col {
      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 8px;
    }
    .middle-icons {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .wallet-icon {
        stroke-width: 1.5;
        color: $main-orange;
      }
      .medical-icon {
        stroke-width: 1.5;
        color: $primary-color;
      }
      .payment-icon {
        stroke-width: 1.5;
        color: #7879f1;
      }
    }
    .title-col {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 0;
      // padding-right: 0;
      // padding-left: 0;
    }
    .title {
      font-size: 12px;
      color: #757575;
      line-height: 14px;
      padding-left: 5px;
    }
    .value-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .value {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      line-height: 16px;
      @media screen and (max-width: 800px) {
        font-size: 13px;
      }
    }
  }

  .last-row {
    // padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #00000026;
    &.child-open {
      border-bottom: 1px solid $primary-color;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
      @media screen and (max-width: 600px) {
        .tools {
          width: 100%;
        }
      }
    }
    .first-part {
      padding: 16px 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 910px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 6px 24px;
        @media screen and (max-width: 600px) {
          flex-direction: row;
          // padding: 16px 24px;
        }
      }
      .last-icons {
        color: #b3b3b3;
        margin-right: 8px;
        display: flex;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .last-values {
        font-size: 12px;
        font-weight: 600;
      }
      .first-col {
        display: flex;
        align-items: center;
      }
      .second-col {
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
    .tools {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 10px;
      height: 100%;
      line-height: 3;
      min-width: max-content;
      background-color: $primary-bg-color-with-oppacity;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        line-height: 1;
      }
      .locationIcon {
        color: $third-gray;
        svg {
          height: 20px;
          width: 20px;
        }
        line-height: 1;
      }

      .show-more {
        color: $primary-color;
        background: none;
        border: none;
        font-weight: 600;
        font-size: 12px;
        .icon {
          svg {
            transition: 0.5s;
            width: 16px;
            height: 16px;
            stroke-width: 2;
            rotate: 0deg;
            margin-left: 2px;
          }
        }
        .rotate {
          svg {
            rotate: -180deg;
          }
        }
      }
      .number-of-children {
        height: 18px;
        width: 18px;
        margin-left: 2px;
        margin-right: 8px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-bg-color;
        color: $light-bg-color;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    .third-row {
      justify-content: space-around;
      .first-col,
      .middle-col,
      .last-col {
        flex-direction: column;
        justify-content: center;
        gap: 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .first-row {
      gap: 8px;
      .name-date-box {
        .name-box {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    box-shadow: none;
    .second-row {
      background-color: #f5f5f580;
      padding: 8px 16px;
      gap: 8px;
      border-bottom: 1px solid #d9d9d9;
      .icon {
        color: $main-orange;
      }
      // .first-col,
      // .second-col {
      //   border-bottom: 1px solid #d9d9d9;
      //   padding-bottom: 8px;
      // }

      .first-col,
      .second-col,
      .third-col {
        padding-left: 4px;
        justify-content: flex-start;
      }
    }
    .third-row {
      border: none;
      flex-direction: column;
      .seprator {
        height: 1px;
        width: 100%;
        background-color: #cce4e3;
      }
      .first-col,
      .middle-col,
      .last-col {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        margin: 0;
        gap: 8px;
      }
    }
    .last-row {
      background-color: rgb(#cce4e3, 0.2);
    }
  }
}

.child-container {
  display: flex;
  background-color: #fafafa;
  // max-width: 100%;
  width: 100%;
  border-bottom: 1px solid $primary-color;
  .first-row {
    background-color: $second-gray;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    cursor: pointer;
    &:hover {
      background-color: #e5e5e5;
    }
  }
  .first-col {
    display: flex;
    .icon {
      color: #a6a6a6;
      height: 22px;
      width: 22px;
    }
  }
  .name-col {
    writing-mode: vertical-lr;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $primary-color;
    background-color: $primary-bg-color-with-oppacity;
    line-height: 3;
    @media screen and (max-width: 850px) {
      line-height: 2;
    }
    font-weight: 600;
    font-size: 14px;
    // display: inline;
    svg {
      height: 18px;
      width: 18px;
      stroke-width: 2;
      margin-bottom: 3px;
    }
  }
  .info-col {
    width: 100%;
    // display: inline;
    @media screen and (max-width: 850px) {
      .third-row {
        padding: 8px 10px;
      }
    }
  }
}

.modal-content {
  .required-note {
    margin-bottom: 28px;
    // position: absolute;
    // bottom: 5px;
    // left: 5px;
    font-size: 13px;
    color: #777;
    font-style: italic;
  }
  .red-star {
    color: $primary-danger-color;
    font-size: 14px;
    font-weight: 600;
  }
  .add-btn {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    background-color: $primary-bg-color;
    color: $light-bg-color;
    font-size: 20px;
    font-weight: bold;
    border: none;
    &:hover {
      background-color: $primary-hover-color;
    }
    &:active {
      background-color: $primary-active-color;
    }
  }
  .input {
    input {
      height: 42px;
    }
    &.not-valid {
      div:last-child {
        border: 1px solid $primary-danger-color;
        background-color: #fcf2f2;
      }
    }
    &.invalid {
      background-color: #fcf2f2;
    }
  }
  h4 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-style: italic;
    color: $primary-active-color;
  }
}
.details-container {
  .loc-info,
  .physical-address,
  .mailing-address,
  .owner,
  .man-doc,
  .contact,
  .bank-info {
    // display: inline-block;
    @media only screen and (max-width: 600px) {
      // display: block;
      margin: 0;
      margin-bottom: 15px;
    }
    vertical-align: top;
    // margin: 15px;
    padding: 15px;
    border-bottom: 1px solid $main-orange-with-oppacity;
    // border-radius: 8px;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    color: $main-orange;
    margin-bottom: 5px;
  }
  .item {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    color: $fourth-gray;
    span {
      // font-weight: 400;
      color: $primary-color;
    }
  }
  :last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  @media only screen and (max-width: 600px) {
    :last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
    margin-top: 10px;
    .row {
      .name {
        font-size: 16px;
      }
    }
  }
}
