@import "../../colors.scss";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  .container {
    margin: auto;

    .img-container {
      max-width: 100%;
      overflow: hidden;
      text-align: center;

      img {
        width: 100%;
      }
    }

    .email-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      p {
        font-weight: bold;
      }
    }

    .logo-container {
      text-align: center;

      img {
        max-width: 240px;
      }
    }

    .welcome-container {
      text-align: center;
    }

    h1,
    h4 {
      margin: 20px 0 40px 0;
      font-weight: 700;
    }
  }

  .input-text-box,
  .input-text-box-warning {
    border: 1px solid $primary-hover-color;
    min-height: 54px;

    &:focus {
      // outline: none;
      box-shadow: none;
      border: 2px solid $primary-hover-color;
    }
  }

  .input-text-box-warning {
    border: 1px solid #cc2929;
    background-color: rgb(204, 41, 41, 0.1);

    &:focus {
      border: 2px solid #cc2929;
      background-color: rgb(204, 41, 41, 0.1);
    }
  }

  .hint {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .checked {
    color: #03a927;
  }

  .warning-error {
    color: #cc2929;
  }

  .input-container {
    position: relative;
  }

  .view-icon {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //   top: 6px;
    // ???
    color: gray;
    cursor: pointer;
  }

  .input-lable {
    // margin-top: 20px;
    font-size: 15px;
    font-weight: 700;
  }
}
