@import "../../colors.scss";

.main {
  min-height: 100dvh;
  background-color: $second-gray;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 20px;
  .container {
    max-width: 600px;
    // width: 40%;
    padding: 40px 20px;
    box-shadow: 0px 8px 4px 0px #00000029;
    background-color: $light-bg-color;
    h5 {
      text-align: center;
      margin-block: 10px;
    }
    h6 {
      margin-top: 10px;
      font-weight: bold;
      a {
        color: black;
        font-size: 1rem;
        text-decoration: underline;
      }
    }
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .company {
        display: flex;
        align-items: center;

        .logo {
          img {
            width: 50px;
          }
        }
        .name {
          font-size: 1.25rem;
          margin-left: 20px;
        }
      }
      .date {
        text-align: center;
      }
    }
    .financing {
      width: 100%;
      border: 1px solid $fourth-gray;
      margin-block: 20px;
      padding: 20px;
      .title {
        font-size: 18px;
        font-weight: bold;
        color: $primary-color;
        white-space: nowrap;
      }
      .seperator {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid $primary-border-color;
      }
      .detailes {
        // border-bottom: 1px solid $primary-border-color;
        padding-block: 10px;
        .title {
          font-size: 12px;
          color: $primary-text-color;
          .icon {
            font-size: 16px;
            color: $main-orange;
            margin-right: 5px;
          }
        }
        .amount {
          font-size: 14px;
          text-align: right;
          font-weight: bold;
        }
      }
      .monthly {
        // border-bottom: 1px solid $primary-border-color;
        padding-top: 20px;
        .title {
          font-size: 16px;
          color: $primary-text-color;
          .icon {
            font-size: 16px;
            color: $main-orange;
            margin-right: 5px;
          }
        }
        .amount {
          font-size: 24px;
          text-align: right;
          font-weight: bold;
        }
      }
    }
    .billing {
      background-color: $gray-color-with-oppacity;
      padding-block: 8px;
    }
  }
}
.modal-content {
  text-align: center;
}
.bold {
  font-weight: bold;
}
.input {
  input {
    height: 42px;
  }
  &.not-valid {
    div:last-child {
      border: 1px solid $primary-danger-color;
      background-color: $primary-danger-bg;
    }
  }
  &.invalid {
    background-color: $primary-danger-bg;
  }
}
