@import "../../../colors.scss";

.container {
  position: relative;
  margin-bottom: 20px;
  padding: 0;
  .row,
  .col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .col {
    padding: 10px;
    border-radius: 8px;
    width: 48%;
    background-color: $light-bg-color;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
    .count {
      padding: 0px 20px 0 10px;
      font-size: 32px;
      font-weight: bold;
      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
    }
    .title {
      border-radius: 4px;
      width: 100%;
      padding: 10px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
      .icon {
        margin-right: 10px;
      }
    }
  }
  .user {
    .title {
      background-color: $primary-bg-color-with-oppacity;
      .icon {
        color: $main-green;
      }
    }
  }
  .location {
    .title {
      background-color: $main-orange-with-oppacity;
      .icon {
        color: $main-orange;
      }
    }
  }
}
