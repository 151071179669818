@import "../../../../colors.scss";
@import "../../../../global.scss";

:root {
  --padding-sides: var(--global-padding-sides);
  --menu-width: 27vw;
  --main-width: 100vw;
  --btn-pos-left: calc(var(--menu-width) - 13px);
  @media screen and (max-width: 2000px) {
    --menu-width: 30vw;
  }
  @media screen and (max-width: 1600px) {
    --menu-width: 33vw;
  }
  @media screen and (max-width: 1200px) {
    --menu-width: 40vw;
  }
  @media screen and (max-width: 900px) {
    --menu-width: calc(100vw - var(--padding-sides));
    --main-width: calc(200vw - 2 * var(--padding-sides));
  }
}

body {
  overflow-x: hidden;
}
.container {
  contain: layout paint size;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  // .header {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 0 20px;
  //   height: 70px;
  //   border-bottom: 2px solid $primary-color;
  //   background-color: $main-orange;
  //   color: white;
  //   font-size: 32px;
  //   font-weight: 600;
  //   .search {
  //     display: flex;
  //     align-items: center;
  //     cursor: pointer;
  //     svg {
  //       stroke-width: 2;
  //       font-size: 20px;
  //       // margin-right: 10px;
  //     }
  //   }
  // }
  main {
    position: absolute;
    display: flex;
    justify-content: start;
    gap: 0;
    bottom: 0;
    left: 0;
    top: 63px;
    transition: 0.5s;
    @media screen and (max-width: 600px) {
      top: 53px;
    }
    width: var(--main-width);
    padding: 0;
    &.closed-side-bar {
      left: calc(var(--menu-width) * -1 + var(--padding-sides));
      width: calc(var(--main-width) + var(--menu-width) - var(--padding-sides));
    }
    .content {
      transition: 0.5s;
      width: calc(var(--main-width) - var(--menu-width));
      opacity: 1;
      &.closed-side-bar {
        border-left: 1px solid $main-orange;
      }
      @media screen and (max-width: 900px) {
        opacity: 0;
        &.closed-side-bar {
          opacity: 1;
        }
      }
      @media screen and (min-width: 900px) {
        &.closed-side-bar {
          width: calc(
            var(--main-width) - var(--menu-width) + var(--menu-width) -
              var(--padding-sides)
          );
          // opacity: 0;
        }
      }
      height: 100%;

      display: flex;
      flex-direction: column;
      padding-right: var(--padding-sides);

      header {
        // padding: 20px;
        // background-color: $primary-bg-color-with-oppacity;
        // color: $primary-color;
        position: sticky;
        top: 0;
        display: flex;
        justify-content: end;
        padding: 22px 0 10px 20px;
        .search-container {
          position: relative;
          width: 40%;
          @media screen and (max-width: 1200px) {
            width: 100%;
          }
          display: flex;
          align-items: center;
          .search-text {
            width: 100%;
            height: 40px;
            // outline-color: $light-bg-color;
            outline: 1px solid whitesmoke;
            border: 1px solid $light-bg-color;
            border-radius: 8px;
            padding: 10px;
            transition: 0.5s;
            &:hover {
              outline: 1px solid $primary-border-color;
            }
            &:focus-visible {
              outline: 1px solid $primary-bg-color;
            }
          }
          .search-icon {
            position: absolute;
            right: 8px;
            cursor: pointer;
            color: $primary-text-color;
          }
        }
      }
      content {
        padding: 20px;
        color: black;
        font-size: 16px;
        font-weight: 400;
        overflow-y: auto;
        p {
          text-align: left;
        }
      }
    }
  }
}

.sidebar {
  overflow-y: auto;
  padding: 20px;
  width: var(--menu-width);
  height: 100%;
  background-color: white;
  padding-left: var(--padding-sides);
  border-right: 1px solid $main-orange;
  position: relative;
  opacity: 1;
  transition: 0.5s;
  ul {
    /* Base styling */
    font-weight: 400;
    list-style: none;
    padding: 0;
    li {
      margin: 1em 0;

      /* Button styling */
      button,
      a {
        background: none;
        border: none;
        color: black;
        cursor: pointer;
        text-align: left;
        font-size: 1em;
        padding: 0;
        &:hover {
          text-decoration: underline;
        }
        // &:focus {
        //   outline: 1px solid $main-orange-with-oppacity;
        //   outline-offset: 2px;
        // }
      }
    }
    /* Highlight the active section */
    li.active > a,
    li.active > button {
      color: $primary-color; /* Active color */
      font-weight: 600;
      display: flex;
      position: relative;
      &::before {
        content: "";
        height: 20px;
        width: 6px;
        border-radius: 4px;
        background-color: $main-orange;
        position: absolute;
        left: -13px;
        top: 0px;
      }
    }
    /* Sub-list styling */

    ul {
      margin-left: 1em;
      border-left: 1px solid $main-orange-with-oppacity;
      padding-left: 9px;
    }
  }

  .expand-button {
    svg {
      color: $main-orange;
    }
  }
}

.menu-btn {
  position: absolute;
  top: 30px;
  left: var(--btn-pos-left);

  color: $main-orange;
  background-color: white;
  width: 24px;
  height: 24px;
  z-index: 3;
  cursor: pointer;
}

// /* scrollbars */

// /* Apply scrollbar styles to all scrollable elements globally */
// * {
//   scrollbar-width: thin; /* For Firefox, applies thin scrollbars */
//   scrollbar-color: $primary-color $primary-bg-color-with-oppacity; /* Green thumb and light green track */
// }

content {
  transition: 0.5s;
  scrollbar-width: thin;
  scrollbar-color: transparent white;

  &:hover {
    scrollbar-width: thin; /* For Firefox, applies thin scrollbars */
    scrollbar-color: $primary-bg-color-with-oppacity transparent; /* Green thumb and light green track */
  }
}

.sidebar {
  font-size: 14px;

  transition: 0.5s;
  scrollbar-width: thin;
  scrollbar-color: transparent white;
  &:hover {
    scrollbar-width: thin; /* For Firefox, applies thin scrollbars */
    scrollbar-color: $main-orange-with-oppacity transparent; /* Green thumb and light green track */
  }
}

// /* For Mobile */
// @media (hover: none) {
//   .content {
//     scrollbar-width: thin;
//     scrollbar-color: transparent white;
//   }

//   .content:focus-within {
//     scrollbar-width: thin;
//     scrollbar-color: $primary-bg-color-with-oppacity transparent;
//   }
// }
