@import "../../colors.scss";

.container {
  width: 195px;
  height: 225px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 40px;
  color: black;
  font-size: 1rem;
  .logo {
    height: 195px;
    background-color: $light-bg-color;
    text-align: center;
    line-height: 195px;
    img {
      width: 90%;
    }
  }
  .name {
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding-block: 10px;
  }
  &:hover {
    opacity: 0.7;
  }
}
