@import "../../colors.scss";

.container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .first-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 40px;
    .search-container {
      position: relative;
      width: 40%;
      display: flex;
      align-items: center;
      .search-text {
        width: 100%;
        height: 40px;
        outline-color: $light-bg-color;
        border: 1px solid $light-bg-color;
        border-radius: 8px;
        padding: 10px;
        transition: 0.5s;
        &:hover {
          outline: 1px solid $primary-border-color;
        }
        &:focus-visible {
          outline: 1px solid $primary-bg-color;
        }
      }
      .search-icon {
        position: absolute;
        right: 8px;
        cursor: pointer;
        color: $primary-text-color;
      }
    }
  }

  .filter-container {
    // width: 100%;
    // display: flex;
    // flex-direction: row;
    // padding: 4px 8px;
    // align-items: center;
    // justify-content: flex-start;
    // border: none;
    // background-color: $light-bg-color;
    // border-radius: 8px;
    // gap: 10px;

    width: 100%;
    display: flex;
    padding: 12px;
    align-items: center;

    border: none;
    background-color: $light-bg-color;
    gap: 10px;
    .filter-row {
      width: 100%;
      .filter {
        min-height: 30px;
        width: 100%;
        font-size: smaller;

        &:focus-visible {
          outline: 1px solid $primary-bg-color;
        }
      }
      .range-filter {
        height: 38px;
        @media screen and (max-width: 992px) {
          margin-bottom: 10px;
        }
      }
      .second-filter {
        @media screen and (max-width: 992px) {
          margin-bottom: 10px;
        }
      }
      .third-filter {
        @media screen and (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
      .billings-range-filter {
        height: 38px;

        @media screen and (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
      // .fourth-filter {
      // }
      .price-button {
        width: 100%;
        min-height: 38px;
        // color: $filter-placeholder-color;
        color: $primary-color;
        background-color: $light-bg-color;
        border: 1px solid $filter-border-color;
        border-radius: 8px;
        font-size: smaller;
      }

      .price-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        // background-color: bisque;
        position: relative;
        top: -15px;
      }
    }
  }

  .amount-container {
    // width: 200px;
    height: 100%;
    background-color: $light-bg-color-with-oppacity;
    color: $primary-text-color;
    border: 1px solid $primary-border-color;
    border-radius: 4px;
  }
}

.notShow {
  display: none;
}

// @media screen and (max-width: 650px) {
//   .filter-icon {
//     display: none;
//   }
// }
@media screen and (max-width: 1350px) {
  .container {
    .first-row {
      .search-container {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .container {
    .first-row {
      .search-container {
        width: 60%;
      }
    }
  }
}
