@import "../../colors.scss";
@import "../../global.scss";

:root {
  --header-vertical-padding: 12px;
}

.fixed-container {
  position: sticky;
  top: 0;
  height: 63px;
  width: 100%;
  padding: var(--header-vertical-padding) var(--global-padding-sides);
  // border-bottom: 1px solid #7d7d7d;
  border-bottom: 1px solid $main-orange;

  background-color: #ffffff;
  z-index: 20;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // alt+ shift + f: pretiffy
  .logo-container {
    img {
      display: block;
      max-height: 35px;
    }
  }
}
.user-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    display: none;
  }
  .menu {
    position: absolute;
    transform: translateY(33px) scaleY(1);
    border: 0.5px solid $primary-color;
    background-color: #ffffff;
    padding: 0;
    border-radius: 8px;
    transition: 0.5s;
    color: $main-gray;
    font-size: 12px;
    right: 0;
    min-width: calc(max(100%, 160px));
    overflow: hidden;
    svg {
      width: 20px;
      height: 20px;
    }
    .title {
      background-color: $second-gray !important;
      padding: 16px 24px 16px 24px !important;
      cursor: default;
      font-weight: 400 !important;
      color: black !important;
      svg {
        stroke-width: 1 !important;
        // width: 16px;
        // height: 16px;
      }
    }
    .row-wrapper {
      padding: 8px 16px;
    }
    &.closed {
      transform: translateY(-40px) scaleY(0);
      opacity: 0;
    }
    .row {
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      border: none;
      background: none;
      width: 100%;
      &:hover {
        background-color: #f9f9f9;
        color: $primary-color;
        font-weight: 500;
        svg {
          stroke-width: 2;
        }
      }
      &.second {
        border-top: 1px solid #e0e0e0;
      }
      svg {
        color: $main-orange;
      }
    }
  }
}
.user-container {
  display: flex;
  text-decoration: none;
  color: $primary-color;
  position: relative;
  ////
  cursor: pointer;
  ////
  .user-name {
    font-size: 14px;
    font-weight: 500;
    color: black;
    padding: 2px 0 0 10px;
  }
  img {
    height: 30px;
    //just for present!
    // width: 124px;
    // width: 30px;
    max-width: 90px;
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover;
    margin: 0 10px 0 10px;
  }
}

@media screen and (max-width: 600px) {
  .fixed-container {
    height: 53px;
  }
  .user-container {
    .user-name {
      display: none;
    }
    img {
      display: none;
    }
  }

  .container {
    .logo-container {
      img {
        max-height: 28px;
      }
    }
  }
}
