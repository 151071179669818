@import "../../../colors.scss";

.container {
  position: relative;
  margin-top: 40px;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 0px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: $main-orange;
    }
  }
  .table {
    width: 100%;
    // min-width: 480px;
    border-radius: 8px;
    background-color: $light-bg-color;

    font-size: 14px;
    font-weight: 500;
    color: $main-gray;
    .table-header {
      height: 64px;
      border-bottom: 1px solid $primary-color;

      .main-title {
        font-size: 16px;
        padding-left: 1vw;
        height: 100%;
        color: $primary-text-color;
        @media screen and (max-width: 1350px) {
          font-size: 13px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 12px;
        }
        @media screen and (max-width: 800px) {
          font-size: 11px;
        }
        span {
          margin-left: 8px;
        }
        svg {
          color: $main-orange;
          @media screen and (max-width: 1000px) {
            width: 25px;
            height: 25px;
          }
          @media screen and (max-width: 800px) {
            width: 20px;
            height: 20px;
          }
        }
      }
      .title {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: $primary-text-color;
        vertical-align: middle;
        padding-right: 5px;
        padding-left: 5px;
        @media screen and (max-width: 1350px) {
          font-size: 13px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 12px;
        }
        @media screen and (max-width: 800px) {
          font-size: 11px;
        }
        .num {
          color: $main-orange;
        }
        svg {
          color: $main-orange;
          // margin-right: 8px;
          @media screen and (max-width: 1000px) {
            width: 25px;
            height: 25px;
          }
          @media screen and (max-width: 800px) {
            width: 20px;
            height: 20px;
          }
        }
        img {
          margin-right: 5px;
        }
      }
    }
    // tbody {
    //   tr:hover {
    //     background-color: $second-gray;
    //   }
    // }
  }

  .table-body {
    // border-bottom: 1px solid $primary-color;
    font-size: 14px;
    font-weight: 500;
    // color: $primary-text-color;
    @media screen and (max-width: 1350px) {
      font-size: 13px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 12px;
    }
    @media screen and (max-width: 800px) {
      font-size: 11px;
    }
    /* Sticky Header */

    tr {
      height: 72px;
      text-align: center;
      vertical-align: middle;

      &:nth-child(even) {
        background-color: $primary-bg-color-with-oppacity !important;
      }
    }
    td {
      &:nth-child(1) {
        text-align: left;
        padding-left: 1vw;
        // font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .table-container {
    width: 100px;
  }
}
