.children {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
}

.figure-container {
  width: 100%;

  height: 300px;
  min-height: 100%;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
