@import "../../colors.scss";

.container {
  .btn-row {
    margin-top: 50px;
  }
  .btn {
    width: 100% !important;
  }
  // .btn-container {
  //     padding: 0 5px;
  // }
}

.not-active {
  background-color: #b2b2b2;
}

@media print {
  .btn-row {
    display: none;
  }
  .bootstrap-container {
    max-width: 100%;
  }
}
