@import "../../colors.scss";

.container {
  display: block;
}

.warning-error {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  color: $primary-danger-color;
  padding: 0;
  margin: 0;
  position: relative;
  line-height: 15px;
  // margin-top: 2px;
  .icon {
    // position: absolute;
    // top: 0;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.step-container {
  display: block;
  padding: 25px 0;
}

.step-title {
  text-align: center;
  font-size: 2rem;
  padding-bottom: 26px;
}

.btn-container {
  margin-top: 15px;
  display: flex;
  text-align: center;
  align-content: stretch;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  button.btn-consent {
    width: 150px;
    margin: 0 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.input-wrapper {
  padding-top: 22px;
  position: relative;
}

.input-title {
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: $default-text-color;
  white-space: nowrap;
  span {
    color: $primary-danger-color;
  }
}

@media (max-width: 600px) {
  .btn-container {
    button.btn-consent {
      width: 28%;
      margin: 0 5px;
      font-size: 13px;
    }
  }
}

.section {
  padding-top: 10px;
  position: relative;

  .section-title {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 5px;
  }
}

.checkbox-container {
  margin-top: 5px;
}

.yes-no-container {
  font-size: 14px;
  margin-top: -30px;
}

.required-note {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 13px;
  color: $primary-text-color;
  font-style: italic;
}
