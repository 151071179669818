.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  @media screen and (max-width: 800px) {
    padding: 32px 0;
    width: 95%;
  }
  .new-application-btn {
    position: absolute;
    top: 44px;
    right: 10%;
    padding-inline: 40px;
    font-weight: bold;
  }
}
