@import "../../colors.scss";

.container {
  display: block;
  max-width: 400px;
  margin: auto;
  position: relative;
  // background-color: antiquewhite;
}

// button {
//   width: 100%;
// }

.input-text-box,
.input-text-box-warning {
  border: 1px solid $primary-hover-color;
  min-height: 54px;
  &:focus {
    // outline: none;
    box-shadow: none;
    border: 2px solid $primary-hover-color;
  }
}

.input-text-box-warning {
  border: 1px solid #cc2929;
  background-color: rgb(204, 41, 41, 0.1);
  &:focus {
    border: 2px solid #cc2929;
    background-color: rgb(204, 41, 41, 0.1);
  }
}

.warning-error {
  font-weight: 600;
  color: #cc2929;
}
.input-lable {
  margin-top: 20px;

  font-size: 15px;
  font-weight: 700;
}

p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  &.email {
    font-size: 18px;
    font-weight: 600;
    svg {
      color: #f59c00;
      width: 20px;
      margin-right: 10px;
    }
  }
}
