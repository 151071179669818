@import "../../colors.scss";

.menu-container {
  overflow: hidden; /* Hide scrollbars */
  white-space: nowrap; /* Prevent wrapping */
}

.menu {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #757575;
  background: #ffffff;
  padding: 8px;
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: thin; /* Custom scrollbar width for Firefox */
  scrollbar-color: $primary-bg-color-with-oppacity transparent; /* Custom scrollbar color for Firefox */
  transition: scrollbar-color 0.3s ease, scrollbar-width 0.3s ease; /* Add transition */
  @media screen and (min-width: 800px) {
    justify-content: center;
  }
}

.menu::-webkit-scrollbar {
  height: 4px; /* Custom scrollbar height for WebKit browsers */
  transition: height 0.3s ease; /* Add transition */
}

.menu::-webkit-scrollbar-thumb {
  background-color: $primary-bg-color-with-oppacity; /* Custom scrollbar thumb color */
  border-radius: 10px; /* Rounded scrollbar thumb */
}

.menu::-webkit-scrollbar-track {
  background: transparent; /* Transparent scrollbar track */
}

.menu:hover {
  scrollbar-width: thin; /* Show thin scrollbar on hover */
  scrollbar-color: $primary-bg-color-with-oppacity transparent; /* Change scrollbar color on hover */
}

.menu:hover::-webkit-scrollbar {
  height: 4px; /* Show scrollbar on hover */
}
