.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 40px;
  position: relative;
  .statistics {
    display: inline-block;
  }
}
