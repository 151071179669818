@import "../../colors.scss";

.main {
  background-color: #f5f5f5;
  padding: 40px 20px 0 20px;
  min-height: calc(100dvh - 63px - 164px);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    max-width: 670px;
    // height: 500px;

    padding: 40px 120px;
    background-color: white;
    box-shadow: 0px 8px 4px 0px #00000029;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .title {
      justify-self: center;
      font-weight: bold;
      color: $primary-active-color;
      margin: 10px 0px 40px 0px;
      text-align: center;
    }
    .icon-container {
      text-align: center;
      .icon {
        width: 50px;
        height: 50px;
        padding: 5px;
        text-align: center;
        border-radius: 100%;
        border: 3px solid $primary-active-color;
      }
    }
    .form-group {
      margin-top: 20px;
      padding: 0;
    }
    .input {
      height: 56px;
      border: 1px solid $primary-active-color;
      &:focus {
        box-shadow: 0px 0px 4px 2px $primary-bg-color-with-oppacity;
      }
    }
    .label {
      font-weight: bold;
      color: #1e1e1e;
      // font-size: 20px;
    }
    .email {
      font-style: italic;
      font-weight: bold;
      margin-top: 10px;
      color: $primary-color;
    }
  }
  .last-container {
    max-width: 670px;
    // height: 500px;

    padding: 40px 130px;
    // background-color: white;
    // box-shadow: 0px 8px 4px 0px #00000029;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .title {
      justify-self: center;
      font-weight: bold;
      color: $primary-active-color;
      margin: 10px 0px 40px 0px;
      text-align: center;
    }
    .icon-container {
      text-align: center;
      .icon {
        width: 50px;
        height: 50px;
        padding: 5px;
        text-align: center;
        border-radius: 100%;
        border: 3px solid $primary-active-color;
      }
    }
  }
}
.input-text-box,
.input-text-box-warning {
  input {
    height: 42px;
  }
}

.input-text-box-warning {
  border: 1px solid $primary-danger-color;
  background-color: $danger-bg-color;
  &:focus {
    border: 2px solid $primary-danger-color;
    background-color: $danger-bg-color;
  }
}

.warning-error {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  color: $primary-danger-color;
  svg {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .main {
    padding: 0;
    .container {
      padding: 20px;
    }
  }
}
