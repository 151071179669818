@import "../../colors.scss";

.container {
  // display: flex;
  // flex-direction: column;
  display: inline-block;
  background-color: #ffffff;
  width: 160px;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
  .value-box {
    background-color: #ffffff;
    width: 100%;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 600;
  }

  .type-box {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    span {
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
    }
    svg {
      width: 32px;
      height: 32px;
      &.leads-icon {
        color: $primary-color;
      }
      &.submitted-icon {
        color: $main-green;
      }
      &.prospects-icon {
        color: $main-orange;
      }
      &.pre-approved-icon {
        color: $main-purple;
      }
      &.applications-icon {
        color: $main-orange;
      }
    }

    &.leads-box {
      border-top: 1px solid $primary-color;
      color: $primary-color;
    }
    &.submitted-box {
      border-top: 1px solid $main-green;
      color: $main-green;
    }
    &.prospects-box {
      border-top: 1px solid $main-orange;
      color: $main-orange;
    }
    &.pre-approved-box {
      border-top: 1px solid $main-purple;
      color: $main-purple;
    }
    &.applications-box {
      border-top: 1px solid $main-orange;
      color: $main-orange;
    }
  }

  &.leads {
    background-color: $primary-bg-color-with-oppacity;
    border: 1px solid $primary-color;
  }
  &.submitted {
    background-color: $green-bg-with-oppacity;
    border: 1px solid $main-green;
  }
  &.prospects {
    background-color: $main-orange-with-oppacity;
    border: 1px solid $main-orange;
  }
  &.pre-approved {
    background-color: $purple-with-oppacity;
    border: 1px solid $main-purple;
  }
  &.applications {
    background-color: $main-orange-with-oppacity;
    border: 1px solid $main-orange;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 132px;
    height: 132px;
    .value-box {
      height: 88px;
      font-size: 30px;
      font-weight: 600;
    }

    .type-box {
      height: 44px;

      span {
        font-size: 12px;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 102px;
    height: 102px;
    .value-box {
      height: 66px;
      font-size: 24px;
      font-weight: 600;
    }

    .type-box {
      height: 36px;

      span {
        font-size: 10px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
