@import "../../colors.scss";

.wrapper {
  display: flex;
  // flex-basis: 0;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: center;

  .img-container {
    max-width: 100%;
    overflow: hidden;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .logo-container {
    text-align: center;
    @media screen and (min-width: 800px) {
      margin-bottom: 50px;
    }
    img {
      max-width: 240px;
    }
  }

  .welcome-container,
  &.welcome-container {
    text-align: center;
    .banner {
      display: flex;
      justify-content: center;
      align-items: center;
      .bannerText {
        display: block;
        height: 11vw;
        max-height: 180px;
        position: relative;
        right: -8vw;
        @media screen and (min-width: 1800px) {
          right: -5vw;
        }
        top: 0.5vw;
      }
      .bannerLines {
        position: relative;
        right: 5vw;
        @media screen and (min-width: 1800px) {
          right: 2vw;
        }
        display: block;
        height: 30vw;
        max-height: 540px;
        // opacity: 0.8;
        -webkit-mask-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 1) 25%,
          rgba(0, 0, 0, 0) 100%
        );
        mask-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 1) 25%,
          rgba(0, 0, 0, 0) 100%
        );

        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;

        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
      }
    }
  }
  .welcome-container {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  &.welcome-container {
    color: $light-bg-color;
    background-image: url("../../images/login-img.png");
    background-size: cover;
    background-position: left;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  h4 {
    margin: 20px 0 20px 0;
    font-weight: 700;
  }
}
