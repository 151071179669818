@import "../../colors.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  .statistics {
    display: inline-block;
  }
}
