@import "../../colors.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #757575;
  &.loading {
    background-color: #b2b2b2;
    pointer-events: none;
    &:before {
      background-color: #e0e0e0;
      pointer-events: none;
    }
  }
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $primary-color;
  &.loading {
    background-color: #b2b2b2;
    pointer-events: none;
  }
}

// input:focus + .slider {
//   box-shadow: 0 0 1px $primary-color;
// }

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.loading {
  pointer-events: none;
}
