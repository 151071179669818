.container {
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: 20px;
  user-select: none !important;
  z-index: 0;
  margin-bottom: 50px;
}
.items {
  display: block;
  list-style: none;
}
.item {
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
}
.link {
  padding: 5px 5px;
  min-width: 40px;
}
.link.disabled {
  cursor: default;
  outline: none !important;
}
.viewing-options-container {
  position: absolute;
  right: 20px;
  width: 150px;
  text-align: left;
  top: 0;
}
.react-select__menu {
  top: none;
  bottom: 100%;
}

.btn-dark {
  background-color: #005555 !important;
}
