@import "../../colors.scss";

.print-wrapper {
  @media print {
    font-size: 14px;
    p {
      font-size: 14px;
    }
    h4 {
      font-size: 16px;
      margin: 0;
    }
  }
  .terms-of-use {
    margin-top: 30px;
    text-indent: 0;
    color: black;
  }
  .applicant-signature {
    display: none;
    @media print {
      display: flex;
      justify-content: space-between;
    }
  }
  .print-box {
    color: $primary-color;
    background-color: $primary-bg-color-with-oppacity;
    text-align: center;
    position: relative;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 1rem;
    @media print {
      display: none;
    }
    .button {
      border: 1px solid $primary-active-color;
      border-radius: 5px;
      padding: 3px 6px;
      font-size: 14px;
      font-weight: 500;
      color: $light-bg-color;
      background-color: $primary-bg-color;
      position: absolute;
      top: 8px;
      right: 8px;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        background-color: $primary-hover-color;
      }
      &:active {
        background-color: $primary-active-color;
      }
      svg {
        stroke-width: 2;
        width: 20px;
        height: 20px;
      }
    }

    p {
      text-indent: 0;
      text-align: center;
      margin-bottom: 0;
    }
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 2rem;
  }
  p {
    text-indent: 2em;
    text-align: justify;
    margin-bottom: 1rem;
    // @media screen and (max-width: 600px) {
    //   text-indent: 1em;
    // }
  }
  ul,
  li {
    text-align: justify;
    list-style: none;
  }
  .terms-of-use {
    text-align: center;
    span {
    }
    a {
      font-weight: bold;
      color: black;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 600px) {
    h3 {
      text-align: justify;
    }
  }
}

.modal-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100dvw;
  background-color: $gray-color-with-oppacity;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .modal-container {
    background-color: $light-bg-color;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #0e0e0e26;
    display: flex;
    margin-top: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      .icon-container {
        background-color: $primary-bg-color-with-oppacity;
        width: 80px;
        height: 80px;
        color: $primary-color;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 80%;
          height: 80%;
        }
      }
      h4 {
        color: $primary-color;
      }
    }

    .button {
      border: 1px solid $primary-color;
      border-radius: 8px;
      color: $primary-color;
      width: 50%;
      padding: 5px;
      text-align: center;
      transition: 0.5s;
      cursor: pointer;
      &:hover {
        background-color: $primary-bg-color-with-oppacity;
      }
      &:active {
        background-color: $light-bg-color;
      }
    }
  }
}

.solid-seperator {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid $primary-text-color;
  margin-block: 40px;
}

.dashed-seperator {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed $primary-text-color;
  margin-block: 20px;
}

@media print {
  .terms-of-use {
    display: none;
  }
}

.input-lable {
  font-weight: bold;
  .important {
    color: $secondary-danger-color;
  }
}

.input-phone {
  width: 100%;
  height: 56px;
  padding-left: 20px;
  border-radius: 8px;
  border-color: $primary-border-color;
  outline: none;
  &:focus-visible {
    outline: none;
    border-color: $primary-bg-color;
  }
}
