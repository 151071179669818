@import "../../../colors.scss";

.title {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 600;
}

.toc-link {
  color: $primary-hover-color;
  &:hover {
    text-decoration: underline;
  }
}

.list {
  list-style-type: square; /* Other options: circle, disc, none */
  color: $main-orange;
  ::marker {
    font-size: 20px;
  }
  .list {
    list-style-type: disc;
  }
}

.no-results {
  color: $primary-danger-color;
  font-style: italic;

  // font-size: 14px;
  // margin: 0 0 10px;
}

.text {
  font-size: 14px;
  margin: 0 0 10px;
}

.highlight {
  background-color: rgb(255, 255, 0, 0.6);
  font-weight: 600;
}
