@import "../../colors.scss";

.main {
  background-color: $second-gray;
  padding-top: 40px;
  .container {
    min-height: 70.6dvh;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: $light-bg-color;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px 40px;
    .logo {
      width: 200px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .organization {
        font-size: 20px;
        // font-weight: bold;
        text-align: center;
      }
      img {
        width: 80%;
      }
    }
    .name-section {
      width: 120%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: $second-gray;
      .user-icon {
        font-size: 48px;
        color: $primary-color;
        margin-right: 10px;
      }
      .user-name {
        font-size: 16px;
        font-weight: bold;
      }
      .user-type {
        font-size: 12px;
        font-weight: bold;
        color: $primary-text-color;
        .role-icon {
          color: $main-orange;
          margin-right: 5px;
        }
      }
    }
    .last-section {
      padding-left: 40px;
      color: $primary-text-color;
      width: 100%;
      .email-icon {
        color: $main-orange;
      }
      .icon {
        margin-right: 10px;
      }
      div {
        padding-block: 20px;
        border-bottom: 1px solid $secondary-border-color;
        &:last-child {
          border: none;
        }
      }
      .button {
        border: none;
        outline: none;
        background: none;
        color: $primary-text-color;
        vertical-align: middle;
        &:hover {
          font-weight: bold;
        }
      }
    }
    .change-password {
      width: 80%;
    }
    .success {
      width: 80%;
      margin-top: 80px;
      h4 {
        color: $primary-color;
        text-align: center;
      }
    }
  }
}
