@import "../../../colors.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  .new-application-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: $light-bg-color;
    background-color: $primary-bg-color;
    border: none;
    border-radius: 8px;
    width: 35%;
    font-weight: 400;
    @media screen and (max-width: 800px) {
      width: 38%;
    }
    @media screen and (max-width: 500px) {
      text-wrap: nowrap;
      font-size: 12px;
      gap: 4px;
    }
    transition: 0.5s;
    &:hover {
      background-color: $primary-hover-color;
    }
    &:active {
      background-color: $primary-active-color;
    }
  }
}

// .modal-content {
// }
.input {
  input {
    height: 42px;
  }
  &.not-valid {
    div:last-child {
      border: 1px solid $primary-danger-color;
      background-color: #fcf2f2;
    }
  }
  &.invalid {
    background-color: #fcf2f2;
  }
}
.required-note {
  margin-bottom: 28px;
  // position: absolute;
  // bottom: 5px;
  // left: 5px;
  font-size: 13px;
  color: #777;
  font-style: italic;
}
.red-star {
  color: $primary-danger-color;
  font-size: 14px;
  font-weight: 600;
}
.add-btn {
  height: 56px;
  width: 100%;
  border-radius: 8px;
  background-color: $primary-bg-color;
  color: $light-bg-color;
  font-size: 20px;
  font-weight: bold;
  border: none;
  &:hover {
    background-color: $primary-hover-color;
  }
  &:active {
    background-color: $primary-active-color;
  }
}
// @media only screen and (max-width: 600px) {
//   .container {
//     padding: 20px 0px;
//     .new-application-btn {
//       position: absolute;
//       top: 20px;
//       right: 10%;
//       padding: 10px 20px;
//       font-size: 14px;
//     }
//   }
// }

@media screen and (max-width: 800px) {
  .new-application-btn {
    font-size: 13px;
  }
}
