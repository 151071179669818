@import "../../../colors.scss";

.form-container {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: center;
  max-width: 400px;

  .img-container {
    max-width: 100%;
    overflow: hidden;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .logo-container {
    text-align: center;

    img {
      max-width: 240px;
    }
  }

  .welcome-container {
    text-align: center;
  }

  h4 {
    margin: 20px 0 40px 0;
    font-weight: 700;
  }
}

.input-text-box,
.input-text-box-warning {
  border: 1px solid $primary-hover-color;
  min-height: 54px;
  &:focus {
    box-shadow: none;
    border: 2px solid $primary-hover-color;
  }
}

.warning-error {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  color: #cc2929;
  svg {
    width: 20px;
    height: 20px;
  }
}

.input-text-box-warning {
  border: 1px solid #cc2929;
  background-color: rgb(204, 41, 41, 0.1);
  &:focus {
    border: 2px solid #cc2929;
    background-color: rgb(204, 41, 41, 0.1);
  }
}

.input-lable {
  margin-top: 80px;
  font-size: 15px;
  font-weight: 700;
}
.email-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  p {
    font-weight: bold;
  }
}
