@import "../../colors.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  user-select: none;
  margin: 32px 0;
  .tab {
    width: 134px;
    height: 48px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #d9d9d9;
    transition: 0.5s;
    cursor: pointer;
    svg {
      color: #6f6f6f;
      width: 24px;
      height: 24px;
    }
    &.invitations {
      border-radius: 0 8px 8px 0;
    }

    &.users {
      border-radius: 8px 0 0 8px;
    }
    &.active {
      background-color: #fefefe;
      border: 1px solid #b2b2b2;
      svg {
        color: $primary-bg-color;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    margin: 24px 0;
    .tab {
      width: 114px;
      height: 38px;
      font-size: 14px;
      gap: 5px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
