@import "../../colors.scss";
@import "../../global.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --footer-vertical-padding: 40px;
  --footer-height: 164px;
}

.container {
  position: sticky;
  left: 0;
  right: 0;
  bottom: calc(-1 * var(--footer-height));
  // bottom: 0;
  height: var(--footer-height);
  display: flex;
  flex-direction: column;
  z-index: 20;
  display: flex;

  background-color: $primary-bg-color;
  padding: var(--footer-vertical-padding) var(--global-padding-sides);
  border-top: 3px solid #ff8800;

  .links-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .logos-container {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      cursor: pointer;
      img {
        max-width: 140px;
      }
    }

    .social-media-logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding-top: 20px;

      &.mobile-show {
        display: none;
      }

      &.desktop-show {
        display: flex;
      }

      .social-icon {
        width: 24px;
        height: 24px;
      }
    }

    .internal-links {
      max-width: 320px;
      // height: 80px;
      display: flex;
      flex-direction: row;
      gap: 40px;

      a {
        font-size: 16px;
        font-weight: 300;
      }

      .col {
        display: flex;
        flex-direction: column;
        // justify-content: center;
      }
    }
  }
}

a {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  color: white;
}

@media screen and (max-width: 600px) {
  :root {
    --footer-vertical-padding: 32px;
    --footer-height: 233px;
  }

  .container {
    .links-container {
      flex-direction: column;

      .social-media-logos {
        &.mobile-show {
          display: flex;
        }

        &.desktop-show {
          display: none;
        }
      }

      .logos-container {
        padding-bottom: 10px;
      }

      .internal-links {
        // gap: 90px;

        a {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .container {
    .links-container {
      .internal-links {
        gap: 30px;
      }
    }
  }
}

@media print {
  .container {
    display: none;
  }
}
