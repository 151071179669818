@import "../../colors.scss";

.container {
  width: 100%;
  background-color: $light-bg-color;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 5px 0;
  border: 1px solid #d9d9d9;
  padding: 8px 24px 4px 24px;
  @media screen and (max-width: 800px) {
    padding: 8px 16px 4px 16px;
  }
  button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $light-bg-color;
  }
  .separation {
    height: 1px;
    border-bottom: 1px solid $secondary-border-color;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 48px;

    .name {
      font-size: 18px;
      font-weight: 500;
    }
    .userName,
    .corporateName {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 14px;
      color: $primary-text-color;
      .userIcon {
        color: $main-orange;
        // margin-right: 5px;
      }
    }
    .corporateName {
      // font-style: italic;
      font-size: 16px;
      color: $primary-active-color;
    }
    .tools {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      font-size: 12px;
      font-weight: 600;
      &.more-info {
        gap: 8px;
      }
      .icon {
        border: none;
        outline: none;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: $primary-bg-color-with-oppacity;
        color: $primary-color;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .locationIcon {
        color: $main-orange;
        svg {
          width: 20px;
          height: 20px;
          stroke-width: 2;
        }
      }
      .confirmed {
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        color: $main-green;
        background-color: $main-green-with-oppacity;
        transition: 0.5s;
      }
      .pending {
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        color: $main-orange;
        background-color: $main-orange-with-oppacity;
        transition: 0.5s;
      }
      .location {
        font-weight: bold;
        color: $primary-text-color;
      }
      .show-more {
        font-size: 12px;
        font-weight: 600;
        color: $primary-color;
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
          width: 16px;
          height: 16px;
          stroke-width: 2;
        }
      }
    }
  }
}

.edit-icon {
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  // border-radius: 100%;
  // background-color: $primary-bg-color-with-oppacity;
  background-color: transparent;
  color: $primary-color;
  cursor: pointer;
  :hover {
    color: $main-orange;
  }
}

.details-container {
  .loc-info,
  .physical-address,
  .mailing-address,
  .owner,
  .man-doc,
  .contact,
  .bank-info {
    // display: inline-block;
    @media only screen and (max-width: 600px) {
      // display: block;
      margin: 0;
      margin-bottom: 15px;
    }
    vertical-align: top;
    // margin: 15px;
    padding: 15px;
    border-bottom: 1px solid $main-orange-with-oppacity;
    // border-radius: 8px;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    color: $main-orange;
    margin-bottom: 5px;
  }
  .item {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    color: $fourth-gray;
    span {
      // font-weight: 400;
      color: $primary-color;
    }
  }
  :last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  @media only screen and (max-width: 600px) {
    :last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
    margin-top: 10px;
    .row {
      .name {
        font-size: 16px;
      }
    }
  }
}
