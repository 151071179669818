@import "../../../colors.scss";

.layout {
  background-color: $second-gray;
}

.container {
  display: block;
  min-height: 73dvh;
  background-color: $light-bg-color;
  padding: 20px 80px;
  box-shadow: 0px 8px 4px 0px #00000029;
  @media screen and (max-width: 800px) {
    padding: 10px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 20px;
  }
  @media print {
    max-width: 100vw;
    padding: 0;
    box-shadow: none;
  }
}

.input {
  input {
    height: 42px;
  }
  &.not-valid {
    div:last-child {
      border: 1px solid $primary-danger-color;
      background-color: #fcf2f2;
    }
  }
  &.invalid {
    background-color: #fcf2f2;
  }
}

.step-container {
  display: block;
  // padding: 25px 0;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
  color: $primary-color;
  position: sticky;
  top: 63px;
  z-index: 15;
  background-color: $light-bg-color;
  width: 100%;
  padding: 14px 0 5px 0;
  border-bottom: 1px solid $primary-color;
  box-shadow: 0px 4px 4px 0px $primary-bg-color-with-oppacity;

  @media screen and (max-width: "600px") {
    top: 53px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.process-container {
  // align-items: center;
  // justify-content: space-around;
  width: 100%;
  // display: flex;
}

.step-title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: $primary-color;

  margin: 0 0 0 5px;

  // padding-bottom: 26px;
  // border-bottom: 1px solid $primary-border-color;
  // margin-bottom: 40px;
}

.btn-container {
  margin-top: 15px;
  display: flex;
  text-align: center;
  align-content: stretch;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  button.btn-consent {
    @media print {
      display: none;
    }
    font-weight: 400;
    height: 40px;
    width: 150px;
    margin: 20px 10px;
    @media screen and (max-width: 1000px) {
      width: 120px;
    }
    @media screen and (max-width: 800px) {
      font-size: small;
      width: 90px;
    }
    &:last-child {
      width: 300px;
      margin-right: 0;
      @media screen and (max-width: 1000px) {
        width: 250px;
      }
      @media screen and (max-width: 800px) {
        font-size: small;
        width: 170px;
      }
    }
  }
}

.input-wrapper {
  padding-top: 22px;
  position: relative;
}

.input-title {
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 14px;
  color: $primary-border-color;
}

@media (max-width: 600px) {
  .btn-container {
    button.btn-consent {
      width: 60px;
      margin: 0 5px;
      font-weight: 500;
      font-size: 12px;
      &.disagree {
        width: 100px;
      }
    }
  }
}

.input-container {
  min-height: 67dvh;
  .section {
    padding-top: 10px;
    position: relative;

    .section-title {
      white-space: nowrap;
      font-size: 16px;
      font-weight: 600;
      // text-transform: uppercase;
      text-align: center;
      padding-bottom: 5px;
      margin-bottom: 20px;
      color: $primary-color;
      display: flex;
      gap: 20px;
      align-items: center;
      .line {
        height: 1px;
        width: 100%;
        background-color: $primary-color;
      }
    }
  }
}
.checkbox-container {
  margin-top: 5px;
}

.yes-no-container {
  font-size: 14px;
  margin-top: -30px;
  .check-boxes {
    display: flex;
    gap: 30px;
  }
}
.separator-dashed,
.separator-solid {
  // margin-top: -50px;
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed $primary-text-color;
}
.separator-solid {
  // margin-top: -30px;
  border-bottom: 1px solid $primary-hover-color;
}
.required-note {
  margin-top: 28px;
  // position: absolute;
  // bottom: 5px;
  // left: 5px;
  font-size: 13px;
  color: #777;
  font-style: italic;
}
.red-star {
  color: $primary-danger-color;
  font-size: 14px;
  font-weight: 600;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  //display: none;
  //-webkit-appearance: none;
  // filter: invert(0.5);
  color: $primary-color;
  // background-color: red;
}

.amount-available {
  font-size: 14px;
  font-weight: 700;
  text-align: justify;
  // margin-bottom: 8px;
  .star {
    color: $primary-danger-color;
  }
}
