@import "../../colors.scss";

.container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  // position: relative;
}

.view-icon {
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //   top: 6px;
  // ???
  color: $primary-border-color;
  cursor: pointer;
}

.input-container {
  position: relative;
}

.input-text-box,
.input-text-box-warning {
  border: 1px solid $primary-hover-color;
  min-height: 54px;
  &:focus {
    box-shadow: none;
    border: 2px solid $primary-hover-color;
  }
}

.input-text-box-warning {
  border: 1px solid $primary-danger-color;
  background-color: $danger-bg-color;
  &:focus {
    border: 2px solid $primary-danger-color;
    background-color: $danger-bg-color;
  }
}

.input-lable {
  margin-top: 20px;

  font-size: 15px;
  font-weight: 700;
}

.forgot-password {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  color: black;
  cursor: pointer;
}

.check-box-container {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  color: black;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .check-box {
    margin-right: 10px;
    font-size: 20px;
    border-color: $primary-color;
    &.form-check-input {
      border: 1px solid $primary-hover-color;
    }
    :checked {
      background-color: $primary-color;
      box-shadow: none;
      border: 2px solid $primary-color;
    }
  }
}

.warning-error {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  color: $primary-danger-color;
  svg {
    width: 20px;
    height: 20px;
  }
}

.mfa-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  .mfa-label {
    margin-bottom: 20px;
  }
  .mfa-container {
    width: 100%;
    height: 60px;
    gap: 12px;
    @media screen and (max-width: 600px) {
      height: 40px;
      gap: 6px;
    }
  }
  .character {
    width: 60px;
    border-radius: 10px;
    border-color: $primary-border-color;
    @media screen and (max-width: 600px) {
      width: 40px;
    }
  }
  .character--inactive {
    background-color: white;
  }
}
.main {
  background-color: #f5f5f5;
  padding: 40px 20px 0 20px;
  min-height: calc(100dvh - 63px - 164px);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .container {
    width: calc(100dvw - 140px);
    height: calc(100dvh - 63px - 164px - 40px);
    max-width: none;
    // padding: 40px 120px;
    background-color: #fafafa;
    box-shadow: 0px 8px 4px 0px #00000029;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      justify-self: center;
      // font-weight: bold;
      font-size: 20px;
      text-align: center;
    }
    .type-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 40px;
      .type {
        width: 128px;
        height: 121px;
        background-color: $light-bg-color;
        border-radius: 8px;
        box-shadow: 0px 2px 4px $primary-bg-color-with-oppacity;
        margin-inline: 20px;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon {
          width: 50px;
          height: 50px;
          padding: 5px;
          text-align: center;
        }
        .title {
          color: $primary-text-color;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .form-group {
      margin-top: 20px;
      padding: 0;
    }
    .input {
      height: 56px;
      border: 1px solid $primary-active-color;
      &:focus {
        box-shadow: 0px 0px 4px 2px $primary-bg-color-with-oppacity;
      }
    }
    .label {
      font-weight: bold;
      color: #1e1e1e;
      // font-size: 20px;
    }
    .email {
      font-style: italic;
      font-weight: bold;
      margin-top: 10px;
      color: $primary-color;
    }
  }
}
