@import "../../../colors.scss";

.title {
  font-size: 20px;
  font-weight: bold;
  color: $main-orange;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
}
.main-container {
  margin-top: 40px;
  position: relative;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
.container {
  position: relative;
  margin-top: 20px;
  background-color: $light-bg-color;
  border-radius: 8px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  .wrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    @media screen and (max-width: 800px) {
      button {
        font-size: 13px;
      }
    }
  }
  .input-lable {
    font-size: 16px;
    font-weight: 600;
    @media screen and (max-width: 1000px) {
      font-size: 15px;
    }
    @media screen and (max-width: 800px) {
      font-size: 13px;
    }
  }
  .row,
  .flex-row {
    width: 100%;

    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }
  .flex-row {
    display: flex;
    gap: 8px;
    // &.first-flex-row {
    //   margin-top: 8px;
    // }
    @media screen and (max-width: 1000px) {
      font-size: 14px;

      input {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 800px) {
      font-size: 13px;
      &.first-flex-row {
        flex-direction: column;
      }
      input {
        font-size: 12px;
      }
    }
    .col {
      width: 100%;
    }
  }
  button {
    background-color: $primary-color;
    color: $light-bg-color;
    border-radius: 8px;
    padding: 8px 0;
    border: none;
    transition: 0.5s;
    width: 100%;
    max-width: 300px;
    &:hover {
      background-color: $primary-hover-color;
    }
    &:active {
      background-color: $primary-active-color;
    }
  }
}
.date-input {
  border-radius: 8px;
  @media screen and (max-width: 800px) {
    height: 38px;
  }
}

@media screen and (min-width: 800px) {
  .test {
    input {
      height: 42px;
    }
  }
}
