@import "../../colors.scss";

.container {
  border: 1px solid $secondary-border-color;
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 5px 0;
  .row-wrapper {
    padding: 8px 16px;
    background-color: $light-bg-color;
    border-top: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    .second {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right-side {
      display: flex;
      align-items: center;
      gap: 8px;
      .location-icon {
        color: $main-orange;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .location-nums {
        color: #757575;
        font-weight: 600;
        .num {
          font-size: 14px;
        }
        .text {
          font-size: 12px;
        }
      }
      .show-more {
        color: $primary-color;

        cursor: pointer;
        .text {
          font-size: 10px;
          font-weight: 600;
          line-height: 10px;
        }
        .icon {
          svg {
            transition: 0.5s;
            width: 16px;
            height: 16px;
            stroke-width: 2;
            rotate: 0deg;
          }
        }
        .rotate {
          svg {
            rotate: -180deg;
          }
        }
      }
    }
    .values {
      color: #757575;
      font-size: 12px;
      font-weight: 600;
    }
    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      .briefcase-icon,
      .email-icon {
        width: 20px;
        height: 20px;
        color: $main-orange;
        stroke-width: 2;
      }
    }
  }

  .row-wrapper-two {
    background-color: #fafafa;
    padding: 0px 16px 12px 16px;
    border-top: 1px solid #d9d9d9;
    line-height: 16px;
    transition: 0.5s;
    overflow: hidden;
    // height: auto;
    // transform: translateY(0) scaleY(1);
    &.closed {
      padding: 0 16px;
    }

    .first {
      border-bottom: 1px solid #b3b3b3;
      padding: 12px 0;
    }
    .second {
      padding-top: 12px;
    }
    .first,
    .second {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .first-item {
      display: flex;
      align-items: center;
      gap: 8px;
      .value {
        color: $primary-color;
        font-size: 14px;
        font-weight: 800;
      }
      .location-icon {
        color: $main-orange;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .address-wrapper {
      display: flex;
      gap: 8px;

      .second-item {
        border-left: 1px solid #b3b3b3;
        padding-left: 8px;
        .value {
          font-size: 12px;
          font-weight: 700;
          color: #757575;
        }
      }
      .third-item {
        border-left: 1px solid #b3b3b3;
        padding-left: 8px;
        .value {
          font-size: 12px;
          color: #757575;
        }
      }
    }
  }
  .row {
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .name-box {
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    .user-type {
      border: 1px solid #757575;
      border-radius: 3px;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: 600;
      color: #757575;
    }
  }
  .date-box {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    .download-icon-box {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $primary-bg-color-with-oppacity;
      color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      .download-icon {
        width: 16px;
        height: 16px;
        stroke-width: 2;
      }
    }
    .date {
      display: flex;
      align-items: center;
      color: #757575;
      font-size: 12px;
      font-weight: 600;

      svg {
        margin-right: 2px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    box-shadow: none;

    .row {
      flex-direction: column;
      gap: 5px;
      .name-box {
        width: 100%;
        justify-content: left;
        font-size: 18px;
        font-weight: 600;
        gap: 8px;
        .user-type {
          border: 1px solid #757575;
          border-radius: 3px;
          padding: 2px 4px;
          color: #757575;
          font-size: 11px;
        }
      }
      .date-box {
        width: 100%;
        justify-content: right;
        gap: 8px;
        .date {
          font-size: 11px;
        }
        .download-icon-box {
          width: 24px;
          height: 24px;
          .download-icon {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    .row-wrapper {
      gap: 8px;
      flex-direction: column;
      align-items: flex-start;
    }
    .row-wrapper-two {
      .address-wrapper {
        flex-direction: column;
        gap: 0;
      }
    }
  }
}
