@import "../../colors.scss";

.container {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 4px 0px #00000026;
  // margin: 8px 0;
  width: 100%;
  margin: 5px 0;
  .first-row,
  .second-row,
  .third-row,
  .last-row {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .first-row,
  .second-row {
    padding: 8px;
  }
  .first-row {
    .name-date-box {
      display: flex;
      align-items: center;
      gap: 16px;
      .name-box {
        font-size: 18px;
        font-weight: 500;
      }

      span {
        margin-left: 5px;
        height: 10px;
        line-height: 10px;
        text-align: center;
      }
    }
  }
  .action-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    .date-box {
      font-size: 12px;
      font-weight: 600;
      color: #757575;
      display: flex;
      align-items: center;
      justify-content: end;
      .date-icon {
        width: 18px;
        height: 18px;
        stroke-width: 2;
        margin-right: 5px;
      }
      .buttons-box {
        align-items: center;
        display: flex;
        gap: 16px;
        .icon-box {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: rgb(0, 119, 117, 0.15);
          //? background-color: rgb($primary-color, 0.15);

          color: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .icon {
            width: 20px;
            height: 20px;
            stroke-width: 2;
          }
        }
      }
      .application-status {
        border-radius: 2px;
        padding: 8px;
        font-size: 14px;
        font-weight: 600;
        &.initiated {
          color: #14ae5c;
          background-color: rgba(20, 174, 92, 0.1);
        }
        &.processed-no-lender {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.pending-applicant-submission {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.submitted {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.processing {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.processed-successfuly-with-approval {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.processed-no-approval {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.processed-no-lender {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.processing-error {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.lender-link-clicked {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.applicant-applied {
          color: #5d5fef;
          background-color: rgba(93, 95, 239, 0.1);
        }
        &.abandoned {
          color: #757575;
          background-color: rgba(117, 117, 117, 0.1);
        }
      }
    }
  }
  .second-row {
    background-color: #f5f5f5;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    .icon {
      color: $main-orange;
      height: 22px;
      width: 22px;
    }
    .logo {
      height: 36px;
    }
    .first-col,
    .second-col,
    .third-col {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .first-col {
      justify-content: flex-start;
      color: $third-gray;
    }
    .second-col {
      justify-content: center;
    }
    .third-col {
      justify-content: flex-end;
      color: $third-gray;
    }
  }
  .third-row {
    margin: 0;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #d9d9d9;
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .first-col,
    .middle-col,
    .last-col {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .seprator {
      height: 50px;
      width: 1px;
      background-color: #d9d9d9;
    }
    .first-col {
      padding-left: 0;
      margin-right: 8px;
    }
    .middle-col {
      justify-content: center;
      margin: 0 8px 0 8px;
      .value {
        font-size: 14px;
      }
    }
    .last-col {
      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 8px;
    }
    .middle-icons {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .wallet-icon {
        stroke-width: 1.5;
        color: $main-orange;
      }
      .medical-icon {
        stroke-width: 1.5;
        color: $primary-color;
      }
      .payment-icon {
        stroke-width: 1.5;
        color: #7879f1;
      }
    }
    .title-col {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 0;
      // padding-right: 0;
      // padding-left: 0;
    }
    .title {
      font-size: 12px;
      color: #757575;
      line-height: 14px;
      padding-left: 5px;
    }
    .value-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .value {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      line-height: 16px;
    }
  }

  .last-row {
    padding: 8px;
    display: flex;
    align-items: center;
    .last-icons {
      color: #b3b3b3;
      margin-right: 8px;
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .last-values {
      font-size: 12px;
      font-weight: 600;
    }
    .first-col {
      display: flex;
      align-items: center;
    }
    .second-col {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    .third-row {
      justify-content: space-around;
      .first-col,
      .middle-col,
      .last-col {
        flex-direction: column;
        justify-content: center;
        gap: 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .first-row {
      gap: 8px;
      .name-date-box {
        .name-box {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    box-shadow: none;
    .second-row {
      background-color: #f5f5f580;
      padding: 8px 16px;
      gap: 8px;
      border-bottom: 1px solid #d9d9d9;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      .icon {
        color: $main-orange;
      }
      // .first-col,
      // .second-col {
      //   border-bottom: 1px solid #d9d9d9;
      //   padding-bottom: 8px;
      // }

      .first-col,
      .second-col,
      .third-col {
        padding-left: 4px;
        justify-content: flex-start;
      }
    }
    .third-row {
      border: none;
      flex-direction: column;
      .seprator {
        height: 1px;
        width: 100%;
        background-color: #cce4e3;
      }
      .first-col,
      .middle-col,
      .last-col {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        margin: 0;
        gap: 8px;
      }
    }
    .last-row {
      background-color: rgb(#cce4e3, 0.2);
    }
  }
}
