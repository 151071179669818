@import "../../colors.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
  .select {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .hcg {
      width: 100%;
      display: flex;
      padding: 12px;
      align-items: center;

      border: none;
      background-color: $light-bg-color;
      gap: 10px;
      margin-bottom: 20px;
      font-size: smaller;
      .select {
        max-width: 300px;
      }
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      .new-location {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: $light-bg-color;
        background-color: $primary-bg-color;
        border: none;
        border-radius: 8px;
        font-weight: 400;

        width: 200px;

        transition: 0.5s;
        &:hover {
          background-color: $primary-hover-color;
        }
        &:active {
          background-color: $primary-active-color;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 20px;
    .select {
      flex-direction: column;
      align-items: end;
      gap: 10px;
      .hcg {
        width: 100%;
      }
    }
  }
}
