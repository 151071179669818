@import "../../colors.scss";

// KPI Definition styles
.definitions-Title {
  font-size: 20px;
  font-weight: bold;
  color: $main-orange;
  margin-bottom: 20px;
  margin-top: 40px;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
}

.definition-container {
  background-color: $light-bg-color;
  width: 100%;
  padding: 10px 20px;

  .definition {
    padding-block: 10px;
    border-bottom: 1px solid $secondary-border-color;

    .title {
      width: max-content;
      font-size: 14px;
      font-weight: bold;
      color: $primary-color;
      background-color: $primary-bg-color-with-oppacity;
      margin-bottom: 5px;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
    .text {
      margin: 0;
      font-size: 14px;
      text-align: justify;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
    p {
      font-size: 14px;
      text-align: justify;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }
}
