@import "./colors.scss";
@import "./global.scss";

:root {
  --max-width: 1200px;

  --max-width-for-closed-side-bar: 1200px;
  --padding-sides: var(--global-padding-sides);
  --side-bar-width: 300px;
  --closed-side-bar-width: 90px;
  --company-logo-scale: 1;
  --container-padding-top: 40px;
  --container-min-height: calc(100dvh - 63px - 164px);
}

.container {
  background-color: $second-gray;
  padding: var(--container-padding-top) var(--padding-sides) 0
    var(--padding-sides);
  min-height: var(--container-min-height);
  // position: relative;
}

.side-bar {
  z-index: 2;
  position: fixed;
  width: var(--side-bar-width);
  top: 136px;
  transition: 0.5s;
  margin-bottom: 20px;
  margin-top: 20px;
  // box-shadow: 10px 5px 10px $gray-color-with-oppacity;

  &.closed {
    width: var(--closed-side-bar-width);
  }
}

.content {
  z-index: 3;
  overflow: hidden;
  margin-left: var(--side-bar-width);
  // padding: 30px;
  background-color: #fafafa;
  border-radius: 8px 8px 0 0;
  position: relative;
  min-height: calc(var(--container-min-height) - var(--container-padding-top));
  transition: 0.5s;
  width: calc(100% - var(--side-bar-width));
  box-shadow: 0px 8px 4px 0px #00000029;
  &::after {
    transition: 0.5s;
    content: ""; /* Pseudo-element for inner shadow */
    position: absolute;
    top: -10px;
    left: 0;
    right: -10px;
    bottom: -10px;
    @media screen and (max-width: 1200px) {
      box-shadow: inset 15px 0px 25px #00000029; /* Left-side inner shadow */
    }
    pointer-events: none; /* Ensure it doesn't block clicks */
    z-index: 10; /* Places it above child content */
  }
  &.closed-side-bar {
    margin-left: var(--closed-side-bar-width);
    width: calc(100% - var(--closed-side-bar-width));
    &::after {
      box-shadow: none; /* Left-side inner shadow */
    }
  }
}

.content-base {
  transition: 0.5s;
  position: relative;
  min-height: calc(var(--container-min-height) - var(--container-padding-top));

  // width: calc(98.5vw - 2 * var(--padding-sides) - var(--side-bar-width));
  // right: calc(98.5vw - 2 * var(--padding-sides) - var(--side-bar-width) - 100%);
}

.menu-btn {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  right: 0;
  background-color: #ffffff;
  padding: 10px;
  width: 36px;
  height: 36px;
  stroke-width: 2;
  border-radius: 8px 0px 0px 8px;
  &:hover {
    color: $primary-hover-color;
    background-color: #ffffffb2;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-wrapper {
    min-height: 50px;
    margin-bottom: 8px;
  }
  .company-logo {
    height: calc(80px * var(--company-logo-scale));
    //just for present!
    margin-top: 45px;
    max-width: calc(250px * var(--company-logo-scale));
    // width: calc(200px * var(--company-logo-scale));
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.5s;
    &.closed-side-bar {
      margin-top: 55px;

      height: calc(40px * var(--company-logo-scale));
      max-width: calc(125px * var(--company-logo-scale));
    }
  }
  .company-name {
    //just for present!
    // display: none;
    //
    margin: 0;
    text-align: center;
    font-size: calc(22px * var(--company-logo-scale));
    font-weight: 400;
    transition: 0.5s;
    &.closed-side-bar {
      // scale: 0;
      font-size: 0;
    }
  }
}
//////////////////////////

.navigation {
  margin-top: 10px;
  transition: 0.5s;
  border-top-left-radius: 8px;
  // overflow: hidden;

  &.closed-side-bar {
    margin-top: 0px;
    font-size: 0;
  }
  .nav-list {
    height: 56px;
    background-color: #ffffffb2;
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px;
    border-bottom: 1px solid #b3b3b3;
    cursor: pointer;
    // transition: 0.5s;

    .nav-icon {
      color: #757575;
      height: 32px;
      width: 32px;
      transition: 0.5s;
    }
    .icon {
      color: $main-orange;

      // color: $primary-color;
      position: absolute;
      right: 5px;
      width: 30px;
      height: 30px;
      transition: 0.5s;
    }
    .nav-text {
      margin-left: 5px;
      color: #000000;
      font-size: calc(16px * var(--company-logo-scale));
      font-weight: 400;
      transition: 0.5s;
      &.closed-side-bar {
        font-size: 0 !important;
      }
    }
    &:hover {
      background-color: #ffffff;
      // border-bottom: 1px solid $primary-hover-color;

      border-bottom: 1px solid $main-orange;
      stroke-width: 2;
      .icon {
        color: $primary-color;

        // color: $main-orange;
        // stroke-width: 2;
      }
      .nav-icon {
        color: $primary-hover-color;
      }
    }
    &.active {
      background-color: #ffffff;
      border-bottom: 1px solid $primary-color;
      stroke-width: 2;
      .icon {
        color: $primary-color;
        // color: $main-orange;
        // stroke-width: 2;
      }
      .nav-icon {
        color: $primary-color;
      }
      .nav-text {
        font-size: calc(18px * var(--company-logo-scale));
        font-weight: 600;
      }
    }
  }
}

///////////////////////////////////
.footer-navigation-wrapper {
  z-index: 4;
  height: 80px;
  background-color: #ffffff;
  border-top: 1px solid rgb(0, 119, 117);
  position: sticky;
  bottom: 0;

  // overflow: auto;
  // white-space: nowrap;
}
.footer-navigation {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .nav-list {
    // height: 80px;
    // width: 92px;
    // display: inline-block;
    // text-decoration: none;
    text-align: center;

    .nav-icon {
      color: #757575;
      height: 32px;
      width: 32px;
      transition: 0.5s;
      margin-bottom: 5px;
    }

    .nav-text {
      color: #000000;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      transition: 0.5s;
    }
    &:hover {
      .nav-icon {
        color: $primary-hover-color;
      }
    }
    &.active {
      .icon {
        color: $primary-hover-color;
        // stroke-width: 2;
      }
      .nav-icon {
        color: $main-orange;
      }
      .nav-text {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

@media screen and (min-width: 601px) {
  .footer-navigation-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    --side-bar-width: 250px;
    --closed-side-bar-width: 90px;
    --company-logo-scale: 0.9;
  }
  .navigation {
    margin-top: 0px;
  }

  .content-base {
    width: calc(100% + var(--side-bar-width) - var(--closed-side-bar-width));
    // width: calc(97vw - 2 * var(--padding-sides) - var(--closed-side-bar-width));
    right: calc(var(--side-bar-width) - var(--closed-side-bar-width));
    &.closed-side-bar {
      width: 100%;
      right: 0;
    }
  }
  .logo-container {
    .company-name {
      text-align: center;
      font-size: calc(20px * var(--company-logo-scale));
    }
  }
}

// @media screen and (max-width: 1000px) {
//   .content-base {
//     transition: none;
//     position: absolute;
//     width: calc(
//       100vw - 2 * var(--padding-sides) - var(--closed-side-bar-width)
//     );
//     right: 0;
//   }
// }

@media screen and (max-width: 650px) {
  :root {
    --container-padding-top: 20px;
  }
  .side-bar {
    top: 110px;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --container-min-height: calc(100dvh - 53px - 233px);
  }
  .container {
    padding: 0;
    position: relative;
    min-height: calc(
      var(--container-min-height) - var(--container-padding-top) - 80px
    );
  }
  .content {
    margin-left: 0px;
    background-color: #f5f5f5;
    // background-color: #fafafa;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    min-height: calc(var(--container-min-height) - 80px);
    transition: 0.5s;
    &.closed-side-bar {
      min-height: calc(var(--container-min-height) - 80px);
      margin-left: 0;
      width: 100%;
    }
    .content-base {
      width: 100%;
      right: unset;
    }
  }
  .menu-btn {
    display: none;
  }
  .side-bar {
    display: none;
  }
}

@media screen and (max-width: 470px) {
  .footer-navigation {
    .nav-list {
      &.active {
        .nav-text {
          font-size: 10px;
        }
      }
      .nav-text {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-height: 950px) {
  // :root {
  //   --container-min-height: 500px;
  // }
  .side-bar {
    // position: absolute;
    // top: 126px;
    position: sticky;
    top: 126px;
    align-self: flex-start;
  }
  .container {
    display: flex;
    flex-direction: row-reverse;
    .content {
      margin-left: 0;
    }
  }
}
@media screen and (max-height: 550px) {
  .side-bar {
    top: 80px;
  }
  .container {
    padding-top: 5px;
  }
}
