@import "../../colors.scss";

.container {
  display: flex;
  align-items: center;
  gap: 12px;
  user-select: none;
}
.title {
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  @media screen and (max-width: 800px) {
    text-align: left;
  }
}
.options {
  background-color: $primary-bg-color-with-oppacity;
  border-radius: 8px;
  gap: 12px;
  padding: 8px;
  display: flex;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 8px;
  }
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  height: 30px;
  text-align: center;
  gap: 3px;
  color: $primary-color;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  cursor: pointer;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: center;
  background-color: #ffffff99;
  &:hover {
    background-color: rgba(#ffffff, 0.8);
  }
  &.selected {
    font-weight: 600;
    cursor: auto;
    background-color: $light-bg-color;
    box-shadow: 0px 1px 2px 0px #00777566;
    pointer-events: none;
  }
  svg {
    height: 16px;
    width: 16px;
  }
}
.date {
  font-size: 10px;
  padding: 0px 8px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: $light-bg-color;
  &.under600 {
    display: none;
    @media screen and (max-width: 800px) {
      display: flex;
    }
  }
  &.over600 {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

@media screen and (max-width: 1000px) {
  .container,
  .options {
    gap: 6px;
  }
  .options {
    padding: 6px;
  }
  .option {
    padding: 4px 6px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    width: 100%;
    gap: 0px;
  }
}
