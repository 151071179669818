@import "../../colors.scss";

// .main {
//   position: relative;
// }
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;

  .row-wrapper {
    // padding: 0px 35px;
    width: 95%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 600px) {
      width: 90%;
      // padding: 0 20px;
    }
    .first-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      width: 100%;
      max-width: 600px;
      @media screen and (min-width: 1710px) {
        max-width: 1235px;
      }
      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
    }
    h1 {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: $main-orange;
    }
    .show-data-mode {
      // margin-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
    // padding: 10px 35px;
    @media screen and (max-width: 1710px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 600px) {
      padding: 0;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 95%;
      max-width: 600px;
      // @media screen and (max-width: 1000px) {
      //   max-width: 500px;
      // }
      @media screen and (max-width: 600px) {
        width: 90%;
      }
      // .show-data-mode {
      //   // margin-bottom: 20px;
      //   width: 100%;
      //   display: flex;
      //   justify-content: flex-end;
      // }
      .statistics {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-bottom: 30px;
        @media screen and (max-width: 1000px) {
          max-width: 500px;
        }
        @media screen and (max-width: 800px) and (min-width: 600px) {
          display: table;
          width: 100%;
          span {
            display: table-cell;
            text-align: center;
            max-width: 100px;
          }
        }
      }
    }
  }
}
